import { MongoService } from "./mongo.service";

export class FeatureService {
  private readonly _url = "/feature";
  private readonly mongoService: MongoService;

  public constructor() {
    this.mongoService = new MongoService();
  }

  public get(callback: any) {
    this.mongoService.get(this._url, callback);
  }

  public getBySearchIds(data: any, callback: any) {
    this.mongoService.post(`${this._url}/containsearchs`, data, callback);
  }

  public post(data: any, callback: any): void {
    this.mongoService.post(this._url, data, callback);
  }

  public put(id: string, data: any, callback: any) {
    this.mongoService.put(`${this._url}/${id}`, data, callback);
  }

  public delete(id: string, callback: any) { 
    this.mongoService.delete(`${this._url}/${id}`, callback);
  }
}