import React, { FC } from 'react';
import './Base.scss';
import BaseInput from './BaseInput';

interface BaseFormGeneratorProps {
  config: Array<any>,
}

const BaseFormGenerator: FC<BaseFormGeneratorProps> = (props) => {
  const getElement = ({
    type,
    className,
    name,
    variableName,
    onChange,
    value
  }: any) => {
    switch (type) {
      case "text": 
        return (
          <BaseInput
            type="text"
            id={variableName}
            key={variableName}
            label={name}
            value={value}
            className={className}
            onChange={onChange}
          />
        );
    }
  }

  return (
    <div className="form-fields">
      {props.config.map((element: any) => {
        return getElement(element);
      })}
    </div>
  )
};

export default BaseFormGenerator;