import { createSlice } from '@reduxjs/toolkit';

//Estado global para acompanhar se uma criação ou edição de funcionalidade foi realizado
export const transactionFeatureSlice = createSlice({
  name: 'transactionFeatureExecuted',
  initialState: {
    value: false
  },
  reducers: {
    changeStatusTransactionFeature: (state, action) => {
      state.value = action.payload;
    }
  }
})

export const { changeStatusTransactionFeature } = transactionFeatureSlice.actions

export default transactionFeatureSlice.reducer