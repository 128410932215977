import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import './style.css';
import { close } from '../../slices/modal.slice';
import { openSearchModal } from '../../slices/search-modal.slice';
import { openFeatureModal } from '../../slices/feature-modal.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CreateSearchModal } from './CreateSearchModal';
import { CreateFeatureModal } from './CreateFeatureModal';

export default function Modal() {
  const dispatch = useDispatch();
  const modalOpen = useSelector((state: RootState) => state.modal.value)
  const searchModalState = useSelector((state: RootState) => state.searchModal.value)
  const featureModalState = useSelector((state: RootState) => state.featureModal.value)
  const cancelButtonRef = useRef(null)

  return (
    <>
      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog 
          as="div" 
          className="relative z-10" 
          initialFocus={cancelButtonRef}
          onClose={() => dispatch(close())}
        >  
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h2" className="modal-title text-center font-semibold leading-6 text-gray-900">
                          Criar
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>
                  <div className='text-center mb-2'>
                    <button
                      type="button"
                      className="modal-button rounded-full mt-3 inline-flex justify-center 
                      bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm 
                      ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                      onClick={() => dispatch(openSearchModal())}
                    >
                      Consulta
                    </button>
                  </div>
                  <div className='text-center mb-5'>
                    <button
                      type="button"
                      className="modal-button rounded-full mt-3 inline-flex justify-center 
                      bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm 
                      ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                      onClick={() => dispatch(openFeatureModal())}
                      ref={cancelButtonRef}
                    >
                      Funcionalidade
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <CreateSearchModal 
        searchModalOpen={searchModalState} 
      />
      <CreateFeatureModal 
        featureModalOpen={featureModalState} 
      />
    </>
  )
}