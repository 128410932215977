import 'graphiql/graphiql.css';
import '@graphiql/plugin-code-exporter/dist/style.css';
import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { fetcher } from '../GraphiqlExplorer/fetch';
import GraphiqlExplorer from '../GraphiqlExplorer/GraphiqlExplorer'
import { SearchService } from '../../services/search.service';
import { SelectOption } from '../../types';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  Tab,
  Tabs,
  TabsBody,
  TabsHeader,
  TabPanel
} from '@material-tailwind/react';
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { lintGutter } from "@codemirror/lint";
import { useAuth } from '../../providers/Auth.provider';
import { useDispatch, useSelector } from 'react-redux';
import { closeFeatureModal } from '../../slices/feature-modal.slice';
import { RootState } from '../../store';
import { closeEditFeatureModal } from '../../slices/edit-feature-modal.slice';

const featureTypes = [
  { value: 'individual', label: 'Individual' },
  { value: 'massa', label: 'Em massa' }
]

export type PropsFeatureModal = {
  featureModalOpen: boolean,
  onClick: any,
  featureName?: string,
  featureType?: string,
  featureSearchsIn?: string[],
  featureQuery?: string,
  featureQueryHeaders?: string,
  featureQueryVariables?: string
  featureFields?: string
  featureExecuteWithoutFields?: boolean
}

export function FeatureModal({
  featureModalOpen,
  onClick,
  featureName = '',
  featureType = '',
  featureSearchsIn = [] as string[],
  featureQuery = '',
  featureQueryHeaders = '',
  featureQueryVariables = '',
  featureFields = '',
  featureExecuteWithoutFields = false
}: PropsFeatureModal) {
  const options: SelectOption[] = [];
  const context = useAuth();
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);
  const [searchs, setSearchs] = useState([{}]);
  const [name, setName] = useState(featureName);
  const [type, setType] = useState(featureType);
  const [searchsIn, setSearchsIn] = useState(featureSearchsIn);
  const [query, setQuery] = useState(featureQuery);
  const [queryHeaders, setQueryHeaders] = useState(featureQueryHeaders);
  const [queryVariables, setQueryVariables] = useState(featureQueryVariables);
  const [fields, setFields] = useState(featureFields);
  const [executeWithoutFields, setExecuteWithoutFields] = useState(featureExecuteWithoutFields);
  const editFeatureModalOpen = useSelector((state: RootState) => state.editFeatureModal.value);

  const onClose = () => {
    if(editFeatureModalOpen) {
      dispatch(closeEditFeatureModal())
    } else {
      dispatch(closeFeatureModal());
    }
  }

  const handleChange = (event: React.FormEvent<HTMLInputElement>, callback: any) => {
    callback(event.currentTarget.value);
  }

  const handleChangeSelect = (option: SingleValue<{ value: string; label: string; }>, callback: any) => {
    callback(option?.value);
  }

  const handleChangeSelectMulti = (options: MultiValue<SelectOption>, callback: any) => {
    callback(options?.map(option => option.value));
  }

  const handleChangeExecute = () => {
    setExecuteWithoutFields(!executeWithoutFields);
  }

  const handleChangeQuery = (value: string) => {
    setQuery(value);
  }

  const handleChangeHeaders = (value: string) => {
    setQueryHeaders(value)
  }

  const handleChangeQueryVariables = (value: string) => {
    setQueryVariables(value);
  }

  const handleChangeFields = (value: string) => {
    setFields(value);
  }

  const handleClick = () => {
    const jsonHeaders = JSON.parse(queryHeaders);
    const jsonVariables = queryVariables !== "" ? JSON.parse(queryVariables) : null;
    const jsonFields = JSON.parse(fields)

    const data = {
      name: name,
      executeWithoutFields: executeWithoutFields,
      type: type,
      query: query,
      queryHeaders: jsonHeaders,
      queryVariables: jsonVariables,
      fields: jsonFields,
      searchs: searchsIn
    }

    onClick(data);
  }

  useEffect(() => {
    async function getSearchs() {
      if (context.setHeaders) context.setHeaders();

      const service = new SearchService();
      await service.getWithIdAndName(setSearchs);
    }

    getSearchs();
  }, [context]);

  if (searchs.length > 0) {
    searchs.forEach((search: any) => {
      options.push({ value: search._id, label: search.name })
    });
  }

  return (
    <Transition.Root show={featureModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="search-modal fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="search-modal-container relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h2" className="modal-title text-base text-left font-semibold leading-6 text-gray-900">
                        Criar Funcionalidade
                      </Dialog.Title>
                    </div>
                  </div>
                </div>
                <div className='p-4'>
                  <input
                    type='text'
                    placeholder='Nome da funcionalidade no extranet'
                    className='modal-input rounded-full w-full mb-2'
                    value={name}
                    onChange={(e) => handleChange(e, setName)}
                  />
                  <Select
                    options={featureTypes}
                    className='rounded-full mb-2'
                    placeholder="Tipo de funcionalidade"
                    value={featureTypes.filter(t => type.includes(t.value))}
                    onChange={(o) => handleChangeSelect(o, setType)}
                  />
                  <Select
                    className='mb-6'
                    options={options}
                    placeholder="Consultas que é vinculado"
                    value={options.filter(option => searchsIn.includes(option.value))}
                    onChange={(o) => handleChangeSelectMulti(o, setSearchsIn)}
                    isMulti
                  />
                  <div className='my-2'>
                    <input
                      type="checkbox"
                      checked={executeWithoutFields}
                      onChange={handleChangeExecute}
                    />
                    <label className='ml-2'>Executar sem campos</label>
                  </div>
                  <Tabs value="graphql">
                    <TabsHeader
                      className="bg-transparent"
                      indicatorProps={{
                        className: "bg-gray-900-10 shadow-none !text-gray-900",
                      }}
                      placeholder={''}
                    >
                      <Tab
                        key={"graphql"}
                        value={"graphql"}
                        placeholder={'graphql'}
                      >
                        Graphql
                      </Tab>
                      <Tab
                        key={"fields"}
                        value={"fields"}
                        placeholder={'fields'}>
                        Campos
                      </Tab>
                    </TabsHeader>
                    <TabsBody placeholder={''}>
                      <TabPanel key={"graphql"} value={"graphql"}>
                        <GraphiqlExplorer
                          fetcher={fetcher}
                          query={query}
                          variables={queryVariables}
                          defaultHeaders={queryHeaders}
                          headers={queryHeaders}
                          isHeadersEditorEnabled={true}
                          onEditQuery={handleChangeQuery}
                          onEditHeaders={handleChangeHeaders}
                          onEditVariables={handleChangeQueryVariables}
                        />
                      </TabPanel>
                      <TabPanel key={"fields"} value={"fields"}>
                        <div>
                          <CodeMirror
                            extensions={[json(), lintGutter()]}
                            onChange={handleChangeFields}
                            height="300px"
                            value={fields}
                          />
                        </div>
                      </TabPanel>
                    </TabsBody>
                  </Tabs>
                </div>
                <div className='flex justify-center pb-4 px-4'>
                  <button
                    type="button"
                    className="modal-button bg-blue text-white rounded-full ml-3 
                    mt-3 inline-flex justify-center px-3 py-2 text-sm font-semibold 
                    shadow-sm ring-1 ring-inset ring-gray-300"
                    onClick={handleClick}
                  >
                    Salvar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}