import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { addData, clearData } from 'slices/selected-items.slice';
import { addCompany } from 'slices/selected-company.slice';
import { RootState } from 'store';

import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { gql, useLazyQuery } from '@apollo/client';

import BaseButton from 'components/Base/BaseButton';
import { IconChevronUp, IconSearch } from 'assets/svg/Icons';

import './GraphqlTableResultMUI.scss';
import BaseFormGenerator from 'components/Base/BaseFormGenerator';
import { LinearProgress } from '@mui/material';

const getThreeLevel = (item: any, fieldsToShow: string[]) => {
  const subfield = getTwoLevel(item, fieldsToShow);
  const value = subfield[fieldsToShow[3]]

  return value;
}

const getTwoLevel = (item: any, fieldsToShow: string[]) => {
  const field = getOneLevel(item, fieldsToShow);
  const value = field[fieldsToShow[2]]

  return value;
}

const getOneLevel = (item: any, fieldsToShow: string[]) => {
  const field = item[fieldsToShow[0]];

  if (field) {
    const value = field[fieldsToShow[1]];

    return value;
  }
}

const getColumnValue = (item: any, fieldsToShow: string[]) => {
  const length = fieldsToShow.length;
  let value = null;

  if (length > 1) {
    if (length === 2) {
      value = getOneLevel(item, fieldsToShow);
    }

    if (length === 3) {
      value = getTwoLevel(item, fieldsToShow);
    }

    if (length === 4) {
      value = getThreeLevel(item, fieldsToShow);
    }

    return value
  }

  return typeof (item[fieldsToShow[0]]) === 'boolean' ? item[fieldsToShow[0]] ? 'SIM' : 'NÃO' : item[fieldsToShow[0]]
}

export function GraphqlTableResultMUI() {
  const [page, setPage] = useState(1);
  const [showSpinner, setShowSpinner] = useState(false);
  const [data, setData] = useState({} as any);
  const currentSearch = useSelector((state: RootState) => state.search.value);
  const transactionSearch = useSelector((state: RootState) => state.transactionSearch);
  const graphqlQueryName = currentSearch.graphqlQueryName;
  const graphqlCountName = currentSearch.graphqlCountName;
  const filters = currentSearch.filters;
  const tableFields = currentSearch.fields;
  const query = gql`${currentSearch.query}`;
  const dispatch = useDispatch();
	const leftIsOpen = useSelector((state: RootState) => state.leftSidebar.value);
	const rightIsOpen = useSelector((state: RootState) => state.rightSidebar.value);
	const mobileLayout = useSelector((state: RootState) => state.layoutMode.mobile);

  const [formDetails, setFormDetails] = useState({});

  // Atualiza os valores dos campos dos filtros da tabela
  const onFormChanged = (value: any, dataLabel: string) => {    
    setFormDetails({
      ...formDetails,
      [dataLabel]: value
    });
  };

  // Formata os campos para serem usados no FormGenerator pra formar os filtros da tabela
  const getFormConfig = (onChangeFunc: Function, fields: any) => {
    const formConfig = fields.map((field: any) => {
      return {
        variableName: field.variableName,
        name: field.name,
        type: field.type,
        value: formDetails[field.variableName as keyof {}],
        onChange: (e: any) => onChangeFunc(e.target.value, field.variableName),
      }
    });
    
    return formConfig;
  };

  const [getAdmin] = useLazyQuery(gql`query UsuarioPrincipal($companyId: Float!) {
    getMainAdmin(companyId: $companyId) {
      id
    }
  }`);

  const [pageSize, setPageSize] = useState(graphqlCountName ? 10 : 100);

  let defaultVariables = {
    'pagination': {
      'pageSize': pageSize,
      'page': page
    }
  }

  let variables = defaultVariables;

  let [find] = useLazyQuery(query, {
    variables: variables,
    context: {
      headers: currentSearch.queryHeaders,
    }
  });

  const dataToTable = (result: any) => {    
    if (Array.isArray(result[graphqlQueryName])) {
      return result[graphqlQueryName].map((item: any) => {
        const rowValues = tableFields.map((field: any) => {
          const fieldsToShow = field.graphqlName.includes('.') ? field.graphqlName.split('.') : [field.graphqlName];
          const fieldValue = getColumnValue(item, fieldsToShow);
          const json = `"${field.graphqlName}":"${fieldValue}"`;
    
          return json;
        })

        return JSON.parse(`{${rowValues.join()}}`);
      });
    }

    
    return [result[graphqlQueryName]];
  }

  const search = useCallback(async (variables?: any) => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
    }, 1000);
    setData([]);
    
    let rows = undefined;

    if (variables) {
      rows = await find({ variables: variables, context: { headers: currentSearch.queryHeaders } });
    } else {
      rows = await find();
    }

    const values = await rows.data;

    if (values !== undefined) {
      setData(values);
    } else {
      setData(null)
    }
  }, [setShowSpinner, find, setData, currentSearch.queryHeaders]);

  // Limpa os valores dos filtros da tabela
  const handleCleanFilters = (event: any) => {
    event.preventDefault();

    const formArr = getFormConfig(onFormChanged, filters);
    let formCleared = {};
    
    formArr.forEach((e: any) => {
      let clearValue;
      if (e.type === 'text' || e.type === 'number' || e.type === 'email') {
        clearValue = '';
      }

      formCleared = {
        ...formCleared,
        [e.variableName]: clearValue
      };
    });

    setFormDetails(formCleared);

    search();
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const target = event.target;

    for (let i = 0; i < (target.length - 1); i++) {
      if (target[i].value) {
        if (target[i].type === 'checkbox') {
          if (target[i].checked) {
            const value = target[i].checked;
            const jsonString = `{"${target[i].id}":${value}}`;
            const json = JSON.parse(jsonString);

            variables = { ...variables, ...json };
          }
        } 
        else {
          const value = Number(target[i].value) ? Number(target[i].value) : `"${target[i].value}"`;
          const jsonString = `{"${target[i].id}":${value}}`;
          const json = JSON.parse(jsonString);

          variables = { ...variables, ...json };
        }
      } 
    }

    search(variables);
  };

  function mapTableColumns() {
    const formattedColumns: GridColDef[] = tableFields.map((field: any) => { 
      const defaultWidth = field.graphqlName === 'id' ? 80 : 300;

      return {
        field: field.graphqlName,
        headerName: field.tableName,
        width: field.columnWidth ? field.columnWidth : defaultWidth,
        flex: field.graphqlName === 'name' ? 1 : 0,
      }
    });

    return formattedColumns;
  };

  function getTableContainerClasses() {
    const baseClass = 'table-container';

    let widthClass = 'no-sidebar-closed';
    if (!leftIsOpen || !rightIsOpen) {
      if (!leftIsOpen && !rightIsOpen) {
        widthClass = 'two-sidebar-closed';
      } else {
        widthClass = 'one-sidebar-closed';
      }
    }

    const strArray = [baseClass, widthClass];
    return strArray.join(' ').trim();
  };

  useEffect(() => {
    async function getData() {
      search();
    }

    getData();
  }, [search, pageSize, page, transactionSearch]);

  return (
    <div id="table-result" className={getTableContainerClasses()}>
      <div>
        <form
          id="filters"
          className="table-filter-container"
          onSubmit={handleSubmit}
        >
          {/* trocar condição por !mobileLayout */}
          {true && (
            <>
              <div className="table-filters">
                <BaseFormGenerator
                  config={getFormConfig(onFormChanged, filters)}
                />
              </div>

              <div className="table-buttons">
                <BaseButton
                  type="submit"
                  buttonStyle="filled"
                  round
                  tooltip="Buscar"
                  icon={<IconSearch />}
                />

                <BaseButton
                  type="submit"
                  buttonStyle="filled-secondary"
                  label="LIMPAR"
                  onClick={(e) => handleCleanFilters(e)}
                />
              </div>
            </>
          )}
          {/* trocar condição por mobileLayout */}
          {false && mobileLayout && (
            <>
              <div className="table-filters">
                <BaseButton
                  buttonStyle="flat"
                  label="Filtros"
                  icon={<IconChevronUp />}
                />
              </div>
            </>
          )}
        </form>
      </div>

      <div className="table-data">
        {showSpinner &&
          <LinearProgress />
        }
        {!showSpinner &&
          <div className="progress-bar-placeholder" />
        }
        {data !== null &&
        data[graphqlQueryName] && 
        tableFields &&
          <DataGrid
            rows={dataToTable(data)}
            columns={mapTableColumns()}
            initialState={{
              pagination: {
                paginationModel: { page: page === 0 ? 1 : page, pageSize: pageSize },
              },
            }}
            sx={{
              border: 0,
              borderRadius: 0,
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            rowHeight={40}
            checkboxSelection
            pageSizeOptions={[10, 50, 100]}
            paginationMode="server"
            rowCount={data[graphqlCountName]}
            onRowSelectionModelChange={async (ids: any) => {
              const selectedIds = new Set(ids);

              if (selectedIds.size === 1) {
                let items = selectedIds.values();
                let first = items.next();

                dispatch(addData(first.value));

                if (currentSearch.showLoginButton) {
                  const company = data[graphqlQueryName].filter((item: any) => item.id === first.value);

                  const response = await getAdmin({ variables: { "companyId": company[0].id }, context: { headers: currentSearch.queryHeaders } })
          
                  if (response.data) {
                    const mainUserId = response.data['getMainAdmin'].id;
                    dispatch(addCompany({ 'companyId': company[0].id, 'mainUserId': mainUserId }));
                  }
                }
              } else {
                let items = Array.from(selectedIds);
                
                dispatch(clearData());
                dispatch(addCompany(items));
              }
            }}
            onPaginationModelChange={(model: GridPaginationModel) => {
              setPage(model.page === 0 ? 1 : model.page)
              setPageSize(model.pageSize);
            }}
          />
        }
      </div>
    </div>
  )
}