
import { createSlice } from '@reduxjs/toolkit';

//Estado global para guardar se a barra lateral de listagem de funcionalidades está aberta ou não
export const rightSidebarSlice = createSlice({
  name: 'rightSidebar',
  initialState: {
    value: true
  },
  reducers: {
    toggleRight: (state) => { state.value = !state.value },
    openRight: (state) => { state.value = true },
    closeRight: (state) => { state.value = false }
  }
})

export const { openRight, closeRight, toggleRight } = rightSidebarSlice.actions

export default rightSidebarSlice.reducer