import { useState } from "react";
import { AuthService } from "../services/auth.service";
import { useAuth } from "../providers/Auth.provider";
import { useNavigate } from "react-router-dom";
import { ReturnModal } from "../components/ReturnModal/ReturnModal";

export default function Login() {
	const context = useAuth();
	const navigate = useNavigate();
	const service = new AuthService();
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [codeSended, setCodeSended] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);
	const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState(false);
	
	const verifyTokenAndAddInContext = (result: any) => {
		if(!result.response) {
			if (context.setProfile) {
				context.setProfile(result);
				navigate("/");
			}
		}
		else if (result.response.data.status !== 200 || result.reponse.data.status !== 201){
			setHasError(true);
			setMessage("Houve um problema com o usuário ou código de acesso.");
			setShowReturnModal(true);
		}
	}

	const handleChange = (event: React.FormEvent<HTMLInputElement>, callback: any) => {
		callback(event.currentTarget.value);
	}

	const handleClickLogin = () => {
		service.login({ email: email }, console.log);

		setCodeSended(true);
	}

	const handleClickCodeVerification = () => {
		service.verifyToken({ token: code.trim() }, verifyTokenAndAddInContext);
	}

	return (
		<>
			<main style={{ textAlign: 'center' }}>
				<div className="bg-white p-5 mx-auto rounded-md" style={{ width: "400px", marginTop: "200px" }}>
					<div>
						<h2 className="text-left mb-4" style={{ color: "#9ca3af", fontSize: "20px" }}>Login</h2>
						<div className="text-left">
							<div className="my-2">
								<input
									id="email"
									name="email"
									type="email"
									className="rounded-full block w-full border-0 py-2 px-2 
									text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
									placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
									focus:ring-indigo-600 sm:text-sm sm:leading-6"
									placeholder='E-mail'
									value={email}
									onChange={(e) => handleChange(e, setEmail)}
									required
								/>
							</div>
						</div>
						{codeSended &&
							<div className="text-left">
								<div className="mb-2">
									<input
										id="code"
										name="code"
										type="code"
										className="rounded-full block w-full border-0 py-2 px-2 
									text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
									placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
									focus:ring-indigo-600 sm:text-sm sm:leading-6"
										placeholder='Código de acesso'
										value={code}
										onChange={(e) => handleChange(e, setCode)}
									/>
								</div>
								<label className="mb-2">
									*Um código de acesso foi enviado para o email informado
								</label>
							</div>
						}
						{codeSended &&
							<div>
								<button
									type="submit"
									className="flex rounded-full w-full justify-center
									bg-blue px-3 py-1.5 text-sm font-semibold 
									leading-6 text-white shadow-sm"
									onClick={handleClickCodeVerification}
								>
									Validar código de acesso
								</button>
							</div>
						}
						{!codeSended &&
							<div>
								<button
									type="submit"
									className="flex rounded-full w-full justify-center
								bg-blue px-3 py-1.5 text-sm font-semibold 
								leading-6 text-white shadow-sm"
									onClick={handleClickLogin}
								>
									Entrar
								</button>
							</div>
						}
					</div>
				</div>
			</main>
			{showReturnModal &&
        <ReturnModal
          message={message}
          hasError={hasError}
          show={showReturnModal}
          closeModal={setShowReturnModal}
        />
      }
		</>
	);
}