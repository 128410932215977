import { createSlice } from '@reduxjs/toolkit';

//Estado global para guardar a empresa selecionada na tabela
export const selectedCompanySlice = createSlice({
  name: 'company',
  initialState: {
    value: {
      'mainUserId':'',
      'companyId':''
    } as any
  },
  reducers: {
    addCompany: (state, action) => {
      console.log(action.payload);
      state.value = action.payload;
    }
  }
})

export const { addCompany} = selectedCompanySlice.actions

export default selectedCompanySlice.reducer