
import { createSlice } from '@reduxjs/toolkit';

//Estado global para guardar se a barra lateral de listagem de consultas está aberta ou não
export const leftSidebarSlice = createSlice({
  name: 'leftSidebar',
  initialState: {
    value: true,
  },
  reducers: {
    toggleLeft: (state) => { state.value = !state.value },
    openLeft: (state) => { state.value = true },
    closeLeft: (state) => { state.value = false }
  }
})

export const { openLeft, closeLeft, toggleLeft } = leftSidebarSlice.actions

export default leftSidebarSlice.reducer