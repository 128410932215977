import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { messages } from 'messages';

import { useAuth } from 'providers/Auth.provider';
import { SearchService } from 'services/search.service';
import { openEditSearchModal } from 'slices/edit-search-modal.slice';
import { toggleLeft } from 'slices/left-sidebar.slice';
import { setSearch } from 'slices/search-in-edition.slice';
import { setCurrentSearch } from 'slices/search-to-execution.slice';
import { changeStatusTransactionSearch } from 'slices/transaction-search.slice';

import BaseButton from "components/Base/BaseButton";
import { DeleteModal } from 'components/DeleteModal/DeleteModal';
import { ReturnModal } from 'components/ReturnModal/ReturnModal';
import { EditSearchModal } from 'layout/Modal/EditSearchModal';

import './Sidebar.scss';
import { IconChevronLeft, IconChevronRight, IconPen, IconTrash } from 'assets/svg/Icons';

export default function LeftSidebar() {
  const context = useAuth();
  const dispatch = useDispatch();
  const [searchs, setSearchs] = useState([] as any[]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [searchIdToDelete, setSearchIdToDelete] = useState("");
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const editSearchModal = useSelector((state: RootState) => state.editSearchModal.value)
  const searchEdition = useSelector((state: RootState) => state.searchEdition.value);
  const transactionSearch = useSelector((state: RootState) => state.transactionSearch);

	const leftIsOpen = useSelector((state: RootState) => state.leftSidebar.value);

  const setAllSearchs = (response: any) => {
    setSearchs(response);
  }

  const refreshSearchs = () => {
    dispatch(changeStatusTransactionSearch(true));
  }

  const handleClickEdit = (search: any) => {
    const data = {
      id: search._id,
      name: search.name,
      graphqlQueryName: search.graphqlQueryName,
      graphqlCountName: search.graphqlCountName,
      executeOnInit: search.executeOnInit,
      showLoginButton: search.showLoginButton,
      accessLevel: search.accessLevel,
      query: search.query,
      queryVariables: JSON.stringify(search.queryVariables),
      queryHeaders: JSON.stringify(search.queryHeaders),
      fields: JSON.stringify(search.fields),
      filters: JSON.stringify(search.filters)
    }

    dispatch(setSearch(data));
    dispatch(openEditSearchModal());
  }

  const handleClickDelete = (id: string) => {
    setDeleteModalIsOpen(true);
    setSearchIdToDelete(id);
    setDeleteModalIsOpen(true);
  }

  const deleteSearch = (id: string) => {
    const service = new SearchService();

    service.delete(id, refreshSearchs);

    setMessage(messages["SUCCESS"]);
    setHasError(false);
    setShowReturnModal(true);
  }
  
  function getSidebarClasses() {
    const baseClass = 'sidebar left-sidebar';
    const openClass = leftIsOpen ? 'sidebar-open' : 'sidebar-closed';

    return [baseClass, openClass].join(' ').trim();
  };

  useEffect(() => {
    async function getSearchs() {
      if (context.setHeaders) context.setHeaders();

      const service = new SearchService();
      service.get(setAllSearchs);
    }

    getSearchs();
  }, [context, transactionSearch]);

  return (
    <div id="left" className={getSidebarClasses()}>
      <BaseButton
        id="left-button"
        buttonStyle="filled-secondary"
        round
        className="sidebar-button left-sidebar-button"
        icon={leftIsOpen ? <IconChevronLeft /> : <IconChevronRight />}
        onClick={() => dispatch(toggleLeft())}
      />

      <div className="sidebar-content">
        <span className="text-large text-secondary-text">Consultas</span>
        <div className="sidebar-items">
          {searchs.length > 0 && searchs.map((search: any) => {
            return (
              <div
                className="sidebar-item"
                key={search._id}
              >
                <BaseButton
                  buttonStyle="flat"
                  label={search.name}
                  ellipsis
                  onClick={() => dispatch(setCurrentSearch(search))}
                />

                {context.accessLevel === "admin" &&
                  <div className="sidebar-item-buttons">
                    <BaseButton
                      buttonStyle="card"
                      round
                      tooltip="Editar"
                      icon={<IconPen />}
                      onClick={() => handleClickEdit(search)}
                    />
                    
                    <BaseButton
                      buttonStyle="card"
                      round
                      tooltip="Excluir"
                      icon={<IconTrash />}
                      onClick={() => handleClickDelete(search._id)}
                    />
                  </div>
                }
              </div>
            )
          })}
        </div>
      </div>
      {searchEdition.id !== '' &&
        <EditSearchModal searchModalOpen={editSearchModal} />
      }
      {deleteModalIsOpen &&
        <DeleteModal 
          show={deleteModalIsOpen}
          setShow={setDeleteModalIsOpen}
          handleClickDelete={() => deleteSearch(searchIdToDelete)}
        />
      }
      {showReturnModal &&
        <ReturnModal
          message={message}
          hasError={hasError}
          show={showReturnModal}
          closeModal={setShowReturnModal}
        />
      }
    </div>
  )
}