import { SearchService } from "../../services/search.service";
import { SearchModal } from "../../components/SearchModal/SearchModal"
import { useDispatch } from "react-redux";
import { useState } from "react";
import { ReturnModal } from "../../components/ReturnModal/ReturnModal";
import { closeSearchModal } from "../../slices/search-modal.slice";
import { changeStatusTransactionSearch } from "../../slices/transaction-search.slice";
import { messages } from "../../messages";

export type PropsCreateSearchModal = {
  searchModalOpen: boolean,
}

export function CreateSearchModal({
  searchModalOpen
}: PropsCreateSearchModal) {
  const dispatch = useDispatch();
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState(false);

  const showModal = (response: any) => {
    if (response) {
      dispatch(closeSearchModal());
      setMessage(messages["SUCCESS"]);
      setHasError(false);
      setShowReturnModal(true);
    }

    dispatch(changeStatusTransactionSearch(true));
  };

  const onClick = (data: any) => {
    const service = new SearchService();

    service.post(data, showModal);
  }

  return (
    <>
      <SearchModal searchModalOpen={searchModalOpen} onClick={onClick} />
      {showReturnModal &&
        <ReturnModal
          message={message}
          hasError={hasError}
          show={showReturnModal}
          closeModal={setShowReturnModal}
        />
      }
    </>
  )
}