import AuthProvider from "./providers/Auth.provider";
import Routes from "./routes/Routes";

export default function App() {
	return (
		<AuthProvider>
			<Routes />
		</AuthProvider>
	)
}
