import { createSlice } from '@reduxjs/toolkit';

//Estado global para guardar se a modal de edição de consulta está aberta ou não
export const editSearchModalSlice = createSlice({
  name: 'editSearchModal',
  initialState: {
    value: false
  },
  reducers: {
    openEditSearchModal: (state) => { state.value = true },
    closeEditSearchModal: (state) => { state.value = false}
  }
})

export const { openEditSearchModal, closeEditSearchModal } = editSearchModalSlice.actions

export default editSearchModalSlice.reducer