import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ModalIcon } from './ModalIcon';

export type PropsModal = {
  message: string,
  hasError: boolean,
  show: boolean,
  closeModal: any
}

export function ReturnModal({
  message,
  hasError,
  show,
  closeModal
}: PropsModal) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-30" initialFocus={cancelButtonRef} onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="modal fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                <div className="bg-white p-5">
                  <div>
                    <div className="text-center">
                      <Dialog.Title as="h2" className="modal-title text-base text-left font-semibold leading-6 text-gray-900 flex align-center">
                        <ModalIcon hasError={hasError} />
                        <span className={hasError ? 'ml-2 text-red-900' : 'ml-2 text-green-900'}>{message}</span>
                      </Dialog.Title>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}