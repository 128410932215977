import { useDispatch } from 'react-redux';
import { open } from '../../slices/modal.slice';
import { useEffect, useState } from 'react';
import { useAuth } from '../../providers/Auth.provider';
import { NavLink, useNavigate } from 'react-router-dom';
import { bootDarkmode, toggleDarkmode } from 'slices/darkmode.slice';
import Modal from '../Modal/Modal';

import BaseButton from 'components/Base/BaseButton';
import BaseButtonClose from 'components/Base/BaseButtonClose';
import { IconFlagOutline, IconLightbulb, IconLogout, IconMenu, IconPlus, IconWarning } from 'assets/svg/Icons';

export function Navbar() {
  const context = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidenavIsOpen, setSidenavIsOpen] = useState(false);

  const toggleSidenav = () => {
    setSidenavIsOpen(!sidenavIsOpen);
  }

  const handleClickLogoff = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessLevel');
    localStorage.removeItem('userId');

    navigate('/login');
  }

  useEffect(() => {
    dispatch(bootDarkmode());
  });

  return (
    <>
      <nav id="top-menu">
        <div className="nav-main">
          <BaseButton
            buttonStyle="flat"
            round
            onClick={toggleSidenav}
            icon={<IconMenu />}
          />

          <div className="nav-logo">
            <IconFlagOutline />
          </div>
          
          <span className="nav-title">Extranet</span>
        </div>

        {context.accessLevel === "admin" &&
          <BaseButton
            buttonStyle="flat"
            size="lg"
            onClick={() => dispatch(open())}
            label="Criar"
            icon={<IconPlus />}
          />
        }
      </nav>

      <div
        id="sidenav"
        className={sidenavIsOpen ? 'sidenav-open' : 'sidenav-closed'}
      >
        <BaseButtonClose onClick={toggleSidenav} />

        <div className="navbar-content">
          {context.accessLevel === "admin" &&
            <>
              <NavLink to="/users">
                <BaseButton
                  buttonStyle="flat"
                  label="Cadastro de usuários"
                  icon={<IconPlus />}
                />
              </NavLink>
              <NavLink to="/logs">
                <BaseButton
                  buttonStyle="flat"
                  label="Logs do sistema"
                  icon={<IconWarning />}
                />
              </NavLink>
            </>
          }
        </div>

        <div className="navbar-buttons">
          <BaseButton
            buttonStyle="flat"
            label="Modo escuro"
            icon={<IconLightbulb />}
            onClick={() => dispatch(toggleDarkmode())}
          />
        
          <BaseButton
            buttonStyle="flat"
            label="Sair"
            icon={<IconLogout />}
            onClick={handleClickLogoff}
          />
        </div>
      </div>
      <Modal />
    </>
  )
}