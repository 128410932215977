import BaseInput from '../components/Base/BaseInput';
import { DateField } from './DateField';
import { Field } from './field';
import { SelectField } from './SelectField';

export class FieldBuilder {
  static addTextField(field: Field){
    return (
      <BaseInput
        type="text"
        className={field.classes} 
        label={field.name}
        id={field.variableName}
      />
    )
  }

  static addNumberField(field: Field) {
    return (
      <input 
        type='number' 
        className={`${field.classes} mr-1 mb-1 rounded-full`} 
        placeholder={field.name}
        id={field.variableName}
      />
    )
  }

  static addSelectField(field: Field) {
    return (
      <SelectField field={field} />
    )
  }

  static addCheckField(field: Field) {
    return (
      <div className='mb-1 mr-2 flex items-center'>
        <label className="toggle mr-1">
          <input 
            className={`${field.classes} rounded-full`}
            type="checkbox" 
            id={field.variableName} 
          />
          <span className="slider round"></span>
        </label>
        <span className='ml-1' style={{color:'#ada9a9'}}>{field.name}</span>
      </div>
    )
  }

  static addDateField(field: Field) { 
    return (
      <DateField field={field}/>
    )
  }
}