import { createSlice } from '@reduxjs/toolkit';

//Estado global para guardar os itens selecionados na tabela
export const selectedItemsSlice = createSlice({
  name: 'selected',
  initialState: {
    value: [] as number[]
  },
  reducers: {
    addData: (state, action) => {
      const newArray = [...state.value, action.payload];
      state.value = newArray;
    },
    removeData: (state, action) => { 
      const index = state.value.indexOf(action.payload);
      
      if (index > -1) {
        state.value.splice(index, 1);
      }
    },
    clearData: (state) => { 
      state.value = [];
    }
  }
})

export const { addData, removeData, clearData } = selectedItemsSlice.actions

export default selectedItemsSlice.reducer