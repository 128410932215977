import { createSlice } from '@reduxjs/toolkit';

//Estado global para acompanhar se uma criação ou edição de consulta foi realizado
export const transactionSearchSlice = createSlice({
  name: 'transactionSearchExecuted',
  initialState: {
    value: false
  },
  reducers: {
    changeStatusTransactionSearch: (state, action) => {
      state.value = action.payload;
    }
  }
})

export const { changeStatusTransactionSearch } = transactionSearchSlice.actions

export default transactionSearchSlice.reducer