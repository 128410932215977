import { createSlice } from '@reduxjs/toolkit';

//Estado global para guardar se a modal de consulta está aberta ou não
export const searchModalSlice = createSlice({
  name: 'searchModal',
  initialState: {
    value: false
  },
  reducers: {
    openSearchModal: (state) => { state.value = true },
    closeSearchModal: (state) => { state.value = false}
  }
})

export const { openSearchModal, closeSearchModal } = searchModalSlice.actions

export default searchModalSlice.reducer