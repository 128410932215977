import { createSlice } from '@reduxjs/toolkit';

//Estado global para guardar a funcionalidade que irá abrir na modal e será editada
export const featureEditionSlice = createSlice({
  name: 'featureEdition',
  initialState: {
    value: {
      id: '',
      name: '',
      query: '',
      queryVariables: '',
      queryHeaders: '',
      fields: [],
      searchsIn: [],
      type: '',
      executeWithoutFields: false
    } as any
  },
  reducers: {
    setFeature: (state, action) => {
      state.value = action.payload;
    }
  }
})

export const { setFeature } = featureEditionSlice.actions

export default featureEditionSlice.reducer