import React, { FC } from 'react';
import './Base.scss';
import BaseButton from './BaseButton';
import { IconX } from 'assets/svg/Icons';

interface BaseButtonCloseProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
}

const BaseButtonClose: FC<BaseButtonCloseProps> = (props) => {
  function getButtonClasses() {
    const baseClass = 'close-button';
    return [baseClass, props.className].join(' ').trim();
  };

  return (
    <BaseButton
      className={getButtonClasses()}
      buttonStyle="flat"
      round
      icon={<IconX />}
      onClick={props.onClick}
    />
  )
};

export default BaseButtonClose;