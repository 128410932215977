import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootState } from 'store';

import { closeLeft } from 'slices/left-sidebar.slice';
import { closeRight } from 'slices/right-sidebar.slice';
import { enableMobileLayout, disableMobileLayout } from 'slices/layout-mode.slice';

import RightSidebar from 'layout/Sidebar/RightSidebar';
import LeftSidebar from 'layout/Sidebar/LeftSideBar';
import { Navbar } from 'layout/Navbar/Navbar';
import { EmptyTableResult } from 'layout/TableResult/EmptyTableResult';
import { GraphqlTableResultMUI } from 'layout/TableResult/GraphqlTableResultMUI';

import './Home.scss';

export const SideBarContext = React.createContext<boolean | any>(null);

export function Home() {
	const dispatch = useDispatch();
	const currentSearch = useSelector((state: RootState) => state.search.value);

	const closeSidebars = useCallback(() => {
		dispatch(closeLeft());
		dispatch(closeRight());
	}, [dispatch]);
	
	const handleResize = () => {
		if (window.innerWidth < 768) {
			closeSidebars();
			dispatch(enableMobileLayout());
		}
		else {
			dispatch(disableMobileLayout());
		}
	};

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
	});

  return (
    <div>
      <Navbar />
      <main className="flex">
				<div className="page-container">
					<LeftSidebar />
					{currentSearch.query !== undefined ? <GraphqlTableResultMUI /> : <EmptyTableResult />}
					<RightSidebar />
				</div>
      </main>
      <Outlet />
    </div>
  )
}