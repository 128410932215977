import { Dayjs } from "dayjs";
import { MongoService } from "./mongo.service";

export class LogService {
  private readonly _url = "/log";
  private readonly mongoService: MongoService;

  public constructor() {
    this.mongoService = new MongoService();
  }

  public get(
    page: number, 
    limit: number, 
    callback: any
  ): void {
    this.mongoService.get(`${this._url}/?page=${page}&limit=${limit}`, callback);
  }

  public postWithParams(
    page: number, 
    limit: number, 
    initialDate: Dayjs,
    endDate: Dayjs,
    callback: any): void {
    this.mongoService.post(
      `${this._url}/?page=${page}&limit=${limit}`, 
      { createdAtGte: initialDate, createdAtLte: endDate },
      callback
    );
  }
}