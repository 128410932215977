import { createSlice } from '@reduxjs/toolkit';

//Estado global para guardar se a modal de edição de funcionalidade está aberta ou não
export const editFeatureModalSlice = createSlice({
  name: 'editFeatureModal',
  initialState: {
    value: false
  },
  reducers: {
    openEditFeatureModal: (state) => { state.value = true },
    closeEditFeatureModal: (state) => { state.value = false}
  }
})

export const { openEditFeatureModal, closeEditFeatureModal } = editFeatureModalSlice.actions

export default editFeatureModalSlice.reducer