import { useDispatch, useSelector } from "react-redux";
import { SearchService } from "../../services/search.service";
import { SearchModal } from "../../components/SearchModal/SearchModal"
import { RootState } from "../../store";
import { useState } from "react";
import { ReturnModal } from "../../components/ReturnModal/ReturnModal";
import { closeEditSearchModal } from "../../slices/edit-search-modal.slice";
import { changeStatusTransactionSearch } from "../../slices/transaction-search.slice";
import { messages } from "../../messages";

export type PropsEditSearchModal = {
  searchModalOpen: boolean,
}

export function EditSearchModal({
  searchModalOpen
}: PropsEditSearchModal) {
  const dispatch = useDispatch();
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const searchEdition = useSelector((state: RootState) => state.searchEdition.value);
  
  const showModal = (response: any) => {
    if (response) {
      dispatch(closeEditSearchModal());
      setMessage(messages["SUCCESS"]);
      setHasError(false);
      setShowReturnModal(true);
    }

    dispatch(changeStatusTransactionSearch(true));
  };

  const onClick = (data: any) => {
    const service = new SearchService();

    service.put(searchEdition.id, data, showModal);
  }

  return(
  <>
    <SearchModal
      searchModalOpen={searchModalOpen}
      onClick={onClick}
      searchName={searchEdition.name}
      searchGraphqlQueryName={searchEdition.graphqlQueryName}
      searchGraphqlCountName={searchEdition.graphqlCountName}
      searchAccessLevel={searchEdition.accessLevel}
      searchExecuteOnInit={searchEdition.executeOnInit}
      searchShowLoginButton={searchEdition.showLoginButton}
      searchQuery={searchEdition.query}
      searchQueryHeaders={searchEdition.queryHeaders}
      searchQueryVariables={searchEdition.queryVariables}
      searchFields={searchEdition.fields}
      searchFilters={searchEdition.filters}
    />
    {showReturnModal &&
      <ReturnModal
        message={message}
        hasError={hasError}
        show={showReturnModal} 
        closeModal={setShowReturnModal}
      />
    }
  </>);
}