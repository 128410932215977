import { Navigate } from "react-router-dom";
import { useAuth } from "../providers/Auth.provider";
import { Home } from "../pages/Home";

export const ProtectedRoute = () => {
  const { accessToken } = useAuth();

  if (!accessToken) {
    return <Navigate to="/login" />;
  }

  return <Home />;
};