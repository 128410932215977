import React, { ChangeEventHandler, FC, useMemo } from 'react';
import './Base.scss';

interface BaseInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string,
  className?: string, // classe externa pro input
  value?: string, // valor inicial do input
  label?: string, // título do input
  inputsize?: string, // tamanho (em classe css) do input, ex: lg, md
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const BaseInput: FC<BaseInputProps> = (props) => {
  const baseInputRef = React.createRef<HTMLInputElement>();

  const inputClass = useMemo(() => {
    const baseClass = 'base-input';
    const labelClass = props.label ? 'labeled' : '';
    const valueClass = props.value ? 'has-value' : '';
    const externalClass = props.className || '';

    let sizeClass = '';
    switch (props.inputsize) {
      case 'md': sizeClass = 'input-medium'; break;
      default: sizeClass = 'input-large';
    }

    const strArray = [baseClass, sizeClass, labelClass, valueClass, externalClass];
    return strArray.join(' ').trim();
  }, [props.label, props.inputsize, props.value, props.className]);

  function focusInput() {
    const node = baseInputRef.current;

    if (node) {
      node.focus();
    }
  }

  return (
    <div
      className={inputClass}
      onClick={() => focusInput()}
    >
      <input
        {...props}
        ref={baseInputRef}
        id={props.id}
        className="base-input-native"
        placeholder={props.label ? undefined : props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />

      { props.label &&
        <span className="base-input-label">
          {props.label}
        </span>
      }
    </div>
  )
};

export default BaseInput;