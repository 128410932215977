
import { createSlice } from '@reduxjs/toolkit';

// Estado global para guardar se o layout mostra como desktop ou mobile.
// Controlado a partir do eventListener de resize em Home.tsx
export const layoutModeSlice = createSlice({
  name: 'layoutMode',
  initialState: {
    mobile: false,
  },
  reducers: {
    enableMobileLayout: (state) => { 
      state.mobile = true;
			document.body.classList.add('mobile');
    },
    disableMobileLayout: (state) => { 
      state.mobile = false;
			document.body.classList.remove('mobile');
    },
  }
})

export const {
  enableMobileLayout,
  disableMobileLayout
} = layoutModeSlice.actions

export default layoutModeSlice.reducer