import { createSlice } from '@reduxjs/toolkit';
import { FeatureDto } from '../types';

//Estado global para guardar a funcionalidade que será executada como por exemplo o adiar trial
export const currentFeatureSlice = createSlice({
  name: 'currentFeature',
  initialState: {
    value: {} as FeatureDto
  },
  reducers: {
    setCurrentFeature: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setCurrentFeature } = currentFeatureSlice.actions

export default currentFeatureSlice.reducer