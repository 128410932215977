import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import 'graphiql/graphiql.css';
import '@graphiql/plugin-code-exporter/dist/style.css';
import GraphiqlExplorer from '../GraphiqlExplorer/GraphiqlExplorer';
import { fetcher } from '../GraphiqlExplorer/fetch';
import { AccessLevelField } from '../../layout/Modal/AcessLevelField';
import { 
  Tab, 
  Tabs, 
  TabsBody, 
  TabsHeader, 
  TabPanel 
} from '@material-tailwind/react';
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { lintGutter } from "@codemirror/lint";
import { MultiValue } from 'react-select';
import { SelectOption } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { closeSearchModal } from '../../slices/search-modal.slice';
import { RootState } from '../../store';
import { closeEditSearchModal } from '../../slices/edit-search-modal.slice';

export type PropsSearchModal = {
  searchModalOpen: boolean,
  onClick: any,
  searchName?: string,
  searchGraphqlQueryName?: string,
  searchGraphqlCountName?: string,
  searchAccessLevel?: string[],
  searchExecuteOnInit?: boolean,
  searchShowLoginButton?: boolean,
  searchQuery?: string,
  searchQueryHeaders?: string,
  searchQueryVariables?: string,
  searchFields?: string,
  searchFilters?: string
}

export function SearchModal({
  searchModalOpen,
  onClick,
  searchName,
  searchGraphqlQueryName = '',
  searchGraphqlCountName = '',
  searchAccessLevel,
  searchExecuteOnInit = false,
  searchShowLoginButton = false,
  searchQuery = '',
  searchQueryHeaders = '',
  searchQueryVariables = '',
  searchFields = '',
  searchFilters = '',
}: PropsSearchModal) {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);
  const [name, setName] = useState(searchName);
  const [graphqlQueryName, setGraphqlQueryName] = useState(searchGraphqlQueryName);
  const [graphqlCountName, setGraphqlCountName] = useState(searchGraphqlCountName);
  const [accessLevel, setAccessLevel] = useState(searchAccessLevel);
  const [executeOnInit, setExecuteOnInit] = useState(searchExecuteOnInit);
  const [showLoginButton, setShowLoginButton] = useState(searchShowLoginButton);
  const [query, setQuery] = useState(searchQuery);
  const [queryHeaders, setQueryHeaders] = useState(searchQueryHeaders);
  const [queryVariables, setQueryVariables] = useState(searchQueryVariables);
  const [fields, setFields] = useState(searchFields)
  const [filters, setFilters] = useState(searchFilters);
  const editSearchModalOpen = useSelector((state: RootState) => state.editSearchModal.value);

  const onClose = () => {
    if(editSearchModalOpen) {
      dispatch(closeEditSearchModal())
    } else {
      dispatch(closeSearchModal());
    }
  }

  const handleChange = (event: React.FormEvent<HTMLInputElement>, callback: any) => {
    callback(event.currentTarget.value);
  }

  const handleChangeSelectMulti = (options: MultiValue<SelectOption>, callback: any) => {
    callback(options?.map(option => option.value));
  }

  const handleChangeQuery = (value: string) => {
    setQuery(value);
  }

  const handleChangeHeaders = (value: string) => {
    setQueryHeaders(value);
  }

  const handleChangeQueryVariables = (value: string) => { 
    setQueryVariables(value);
  }

  const handleChangeFields = (value: string) => {
    setFields(value);
  }

  const handleChangeFilters = (value: string) => {
    setFilters(value)
  }

  const handleChangeExecuteOnInit = () => {
    setExecuteOnInit(!executeOnInit)
  }

  const handleChangeShowLoginButton = () => {
    setShowLoginButton(!showLoginButton);
  }

  const handleClick = () => {
    const jsonHeaders = JSON.parse(queryHeaders);
    const jsonVariables = queryVariables !== "" ? JSON.parse(queryVariables) : null;
    const jsonFilters = JSON.parse(filters);
    const jsonTableFields = JSON.parse(fields);

    const data = {
      name: name,
      graphqlQueryName: graphqlQueryName,
      graphqlCountName: graphqlCountName,
      executeOnInit: executeOnInit,
      accessLevel: accessLevel,
      showLoginButton: showLoginButton,
      query: query,
      queryHeaders: jsonHeaders,
      queryVariables: jsonVariables,
      filters: jsonFilters,
      fields: jsonTableFields,
      active: true
    };

    onClick(data);
  }

  return (
    <Transition.Root show={searchModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="search-modal fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="search-modal-container relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h2" className="modal-title text-base text-left font-semibold leading-6 text-gray-900">
                        Criar Consulta
                      </Dialog.Title>
                    </div>
                  </div>
                </div>
                <div className='p-4'>
                  <input 
                    type='text' 
                    placeholder='Nome da consulta no extranet' 
                    className='modal-input rounded-full w-full mb-2'
                    value={name}
                    onChange={(e) => handleChange(e, setName)}
                  />
                  <input 
                    type='text' 
                    placeholder='Nome da consulta no graphql' 
                    className='modal-input rounded-full w-full mb-2'
                    value={graphqlQueryName}
                    onChange={(e) => handleChange(e, setGraphqlQueryName)}
                  />
                  <input 
                    type='text' 
                    placeholder='Nome da consulta de contagem no graphql' 
                    className='modal-input rounded-full w-full mb-2'
                    value={graphqlCountName}
                    onChange={(e) => handleChange(e, setGraphqlCountName)}
                  />
                  <AccessLevelField
                    isMulti
                    onChange={(o: MultiValue<SelectOption>) => handleChangeSelectMulti(o, setAccessLevel)}
                    access={accessLevel}
                  />
                  <div className='my-2'>
                    <input 
                      type="checkbox" 
                      checked={executeOnInit}
                      onChange={handleChangeExecuteOnInit}
                    />
                    <label className='ml-2'>Executar na abertura</label>
                  </div>
                  <div className='my-2'>
                    <input 
                      type="checkbox" 
                      checked={showLoginButton}
                      onChange={handleChangeShowLoginButton}
                    />
                    <label className='ml-2'>Mostrar botão de logar no cliente</label>
                  </div>
                  <Tabs value="graphql">
                    <TabsHeader
                      className="bg-transparent"
                      indicatorProps={{
                        className: "bg-gray-900-10 shadow-none !text-gray-900",
                      }}
                      placeholder={''}
                    >
                      <Tab
                        key={"graphql"}
                        value={"graphql"}
                        placeholder={'graphql'}
                      >
                        Graphql
                      </Tab>
                      <Tab
                        key={"tableFields"}
                        value={"tableFields"}
                        placeholder={'tableFields'}>
                        Campos da Tabela
                      </Tab>
                      <Tab
                        key={"filters"}
                        value={"filters"}
                        placeholder={'filters'}>
                        Campos da filtragem
                      </Tab>
                    </TabsHeader>
                    <TabsBody placeholder={''}>
                      <TabPanel key={"graphql"} value={"graphql"}>
                        <GraphiqlExplorer
                          fetcher={fetcher}
                          query={query}
                          variables={queryVariables}
                          defaultHeaders={queryHeaders}
                          headers={queryHeaders}
                          isHeadersEditorEnabled={true}
                          onEditQuery={handleChangeQuery}
                          onEditHeaders={handleChangeHeaders}
                          onEditVariables={handleChangeQueryVariables}
                        />
                      </TabPanel>
                      <TabPanel key={"tableFields"} value={"tableFields"}>
                        <div>
                          <CodeMirror
                            extensions={[json(), lintGutter()]}
                            height="300px"
                            value={fields}
                            onChange={handleChangeFields}
                          >
                          </CodeMirror>
                        </div>
                      </TabPanel>
                      <TabPanel key={"filters"} value={"filters"}>
                        <div>
                          <CodeMirror
                            extensions={[json(), lintGutter()]}
                            height="300px"
                            value={filters}
                            onChange={handleChangeFilters}
                          >
                          </CodeMirror>
                        </div>
                      </TabPanel>
                    </TabsBody>
                  </Tabs>
                </div>
                <div className='flex justify-center pb-4 px-4'>
                  <button
                    type="button"
                    className="modal-button bg-blue text-white rounded-full ml-3 mt-3 inline-flex justify-center px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300"
                    onClick={handleClick}
                  >
                    Salvar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}