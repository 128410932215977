import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef, useState } from 'react'
import { FieldFactory } from '../../field/field-factory';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { gql, useMutation } from '@apollo/client';
import { ReturnModal } from '../../components/ReturnModal/ReturnModal';
import { messages } from '../../messages';

export type FeatureFormModalProps = {
  modalIsOpen: boolean,
  setModalIsOpen: any,
}

export default function FeatureFormModal({
  modalIsOpen,
  setModalIsOpen
}: FeatureFormModalProps) {
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const selectedItems = useSelector((state: RootState) => state.selectedItems.value);
  const currentFeature: any = useSelector((state: RootState) => state.currentFeature.value);
  const cancelButtonRef = useRef(null);
  const query = gql`${currentFeature.query}`;

  const [executeMutation] = useMutation(query, {
    context: {
      headers: currentFeature.queryHeaders,
    }
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();

    let variables = {};
    const target = event.target;

    if (selectedItems.length > 1) {
      const ids = JSON.parse(`"ids":${selectedItems}`);
      variables = { ...variables, ids }
    } else {
      const id = JSON.parse(`${selectedItems[0]}`);
      variables = { ...variables, id }
    }

    for (let i = 0; i < (target.length - 1); i++) {
      if (target[i].type === 'checkbox') {
        if (target[i].checked) {
          const value = target[i].checked;
          const jsonString = `{"${target[i].id}":${value}}`;
          const json = JSON.parse(jsonString);

          variables = { ...variables, ...json };
        }
      } else {
        const value = Number(target[i].value) ? Number(target[i].value) : `"${target[i].value}"`;
        const jsonString = `{"${target[i].id}":${value}}`;
        const json = JSON.parse(jsonString);

        variables = { ...variables, ...json };
      }
    }

    executeMutation({
      variables: variables,
      context: { headers: currentFeature.queryHeaders }
    })
    .then(() => {
      setMessage(messages["SUCCESS"]);
      setHasError(false);
      setShowReturnModal(true);
    })
    .catch((error) => {
      setMessage(messages["ERROR"]+error.message);
      setHasError(true);
      setShowReturnModal(true);
    });
  }

  const fieldFactory = new FieldFactory();

  return (
    <>
      <Transition.Root show={modalIsOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => setModalIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h2" className="modal-title text-center font-semibold leading-6 text-gray-900">
                          {currentFeature && currentFeature.name}
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="p-4 modal-fields">
                      {currentFeature.fields &&
                        currentFeature.fields.map((field: any) => {
                          return fieldFactory.addField(field);
                        })
                      }
                    </div>
                    <div className="text-center pb-5">
                      <button
                        type="submit"
                        className="modal-button bg-blue text-white rounded-full ml-3 
                        mt-3 inline-flex justify-center px-3 py-2 text-sm font-semibold 
                        shadow-sm ring-1 ring-inset ring-gray-300"
                      >
                        Salvar
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {showReturnModal &&
        <ReturnModal
          message={message}
          hasError={hasError}
          show={showReturnModal}
          closeModal={setShowReturnModal}
        />
      }
    </>
  )
}