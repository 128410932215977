import { createSlice } from '@reduxjs/toolkit';

//Estado global para guardar se a modal de funcionalidade está aberta ou não
export const featureModalSlice = createSlice({
  name: 'featureModal',
  initialState: {
    value: false
  },
  reducers: {
    openFeatureModal: (state) => { state.value = true },
    closeFeatureModal: (state) => { state.value = false}
  }
})

export const { openFeatureModal, closeFeatureModal } = featureModalSlice.actions

export default featureModalSlice.reducer