import { createSlice } from '@reduxjs/toolkit';

//Estado global para guardar a consulta que será executada e irá listar alguma coisa
export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    value: {} as any
  },
  reducers: {
    setCurrentSearch: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setCurrentSearch } = searchSlice.actions

export default searchSlice.reducer