import { MongoService } from "./mongo.service";

export class SearchService {
  private readonly _url = "/search";
  private readonly mongoService: MongoService;

  public constructor() {
    this.mongoService = new MongoService();
  }

  public get(callback: any) {
    this.mongoService.get(this._url, callback);
  }

  public getWithIdAndName(callback: any) {
    this.mongoService.get(`${this._url}/returntwofields`, callback);
  }

  public post(data: any, callback: any): void {
    this.mongoService.post(this._url, data, callback);
  }

  public put(id: string, data: any, callback: any) {
    this.mongoService.put(`${this._url}/${id}`, data, callback);
  }

  public delete(id: string, callback: any) { 
    this.mongoService.delete(`${this._url}/${id}`, callback);
  }
}