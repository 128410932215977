import { SingleValue } from "react-select";
import { SelectOption } from '../types';
import { AccessLevelField } from "../layout/Modal/AcessLevelField";
import { useState } from "react";
import { AccountService } from "../services/account.service";
import { useNavigate } from "react-router-dom";
import { ReturnModal } from "../components/ReturnModal/ReturnModal";
import { messages } from "../messages";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function CreateUsers() {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [password, setPassword] = useState("");
	const [accessLevel, setAccessLevel] = useState("");
	const [showReturnModal, setShowReturnModal] = useState(false);
  const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState(false);

	const showMessage = (response: any) => {
		if (response) {
      setMessage(messages["SUCCESS"]);
      setHasError(false);
      setShowReturnModal(true);
    }
  };

	const handleChange = (event: React.FormEvent<HTMLInputElement>, callback: any) => {
		callback(event.currentTarget.value);
	}

	const handleChangeSelect = (option: SingleValue<{ value: string; label: string; }>, callback: any) => {
		callback(option?.value);
	}

	const handleClick = () => {
		navigate("/");
	}

	const handleClickSubmit = () => {
		const service = new AccountService();

		service.post({
			email,
			password,
			firstName,
			lastName,
			twoFA: true,
			accessLevel
		}, showMessage)
	}

	return (
		<>
			<main style={{ textAlign: 'center' }}>
				<div className="mx-auto text-left" style={{ width: "400px", marginTop: "100px", color: "#0891b2" }}>
					<button type="button" onClick={handleClick}>
						<ArrowBackIcon />
					</button>
				</div>
				<div className="bg-white p-5 mx-auto rounded-md" style={{ width: "400px", marginTop: "20px" }}>
					<div>
						<h2 className="text-left mb-4" style={{ color: "#9ca3af", fontSize: "20px" }}>Cadastro de usuário</h2>
						<div className="text-left">
							<div className="my-2">
								<input
									id="email"
									name="email"
									type="email"
									className="rounded-full block w-full border-0 py-2 px-2 
									text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
									placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
									focus:ring-indigo-600 sm:text-sm sm:leading-6"
									placeholder='E-mail'
									onChange={(e) => handleChange(e, setEmail)}
								/>
							</div>
						</div>
						<div className="text-left">
							<div className="my-2">
								<input
									id="senha"
									name="senha"
									type="password"
									className="rounded-full block w-full border-0 py-2 px-2 
									text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
									placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
									focus:ring-indigo-600 sm:text-sm sm:leading-6"
									placeholder='Senha'
									onChange={(e) => handleChange(e, setPassword)}
								/>
							</div>
						</div>
						<div className="text-left">
							<div className="my-2">
								<input
									id="name"
									name="name"
									type="text"
									className="rounded-full block w-full border-0 py-2 px-2 
									text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
									placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
									focus:ring-indigo-600 sm:text-sm sm:leading-6"
									placeholder='Nome'
									onChange={(e) => handleChange(e, setFirstName)}
								/>
							</div>
						</div>
						<div className="text-left">
							<div className="my-2">
								<input
									id="lastName"
									name="lastName"
									type="text"
									className="rounded-full block w-full border-0 py-2 px-2 
									text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
									placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
									focus:ring-indigo-600 sm:text-sm sm:leading-6"
									placeholder='Sobrenome'
									onChange={(e) => handleChange(e, setLastName)}
								/>
							</div>
						</div>
						<div className="text-left">
							<div className="my-2">
								<AccessLevelField
									isMulti={false}
									onChange={(o: SelectOption) => handleChangeSelect(o, setAccessLevel)}
								/>
							</div>
						</div>
						<div>
							<button
								type="submit"
								className="flex rounded-full w-full justify-center
								bg-blue px-3 py-1.5 text-sm font-semibold 
								leading-6 text-white shadow-sm"
								onClick={handleClickSubmit}
							>
								Cadastrar
							</button>
						</div>
					</div>
				</div>
			</main>
			{showReturnModal &&
				<ReturnModal
					message={message}
					hasError={hasError}
					show={showReturnModal}
					closeModal={setShowReturnModal}
				/>
			}
		</>
	);
}