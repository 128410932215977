const IconChevronDown = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.99945 12.4C7.70095 12.4 7.4023 12.2828 7.17481 12.0484L1.34178 6.04844C0.886074 5.57969 0.886074 4.82031 1.34178 4.35156C1.79748 3.88281 2.53573 3.88281 2.99143 4.35156L7.99945 9.505L13.0086 4.3525C13.4643 3.88375 14.2025 3.88375 14.6582 4.3525C15.1139 4.82125 15.1139 5.58062 14.6582 6.04937L8.82519 12.0494C8.59734 12.2838 8.2984 12.4 7.99945 12.4Z" fill="#53535F"/>
    </svg>
  );
};
const IconChevronLeft = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.34166 7.17636C3.88611 7.63191 3.88611 8.37173 4.34166 8.82728L10.1727 14.6583C10.6283 15.1139 11.3681 15.1139 11.8236 14.6583C12.2792 14.2028 12.2792 13.463 11.8236 13.0074L6.81622 8L11.82 2.99258C12.2755 2.53703 12.2755 1.79721 11.82 1.34166C11.3644 0.886112 10.6246 0.886112 10.1691 1.34166L4.33802 7.17272L4.34166 7.17636Z" fill="#53535F"/>
    </svg>
  );
};
const IconChevronRight = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8236 7.17636C12.2792 7.63191 12.2792 8.37173 11.8236 8.82728L5.99258 14.6583C5.53703 15.1139 4.79721 15.1139 4.34166 14.6583C3.88611 14.2028 3.88611 13.463 4.34166 13.0074L9.34908 8L4.34531 2.99258C3.88976 2.53703 3.88976 1.79721 4.34531 1.34166C4.80086 0.886112 5.54067 0.886112 5.99622 1.34166L11.8273 7.17272L11.8236 7.17636Z" fill="#53535F"/>
    </svg>
  );
};
const IconChevronUp = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.17636 4.34166C7.63191 3.88611 8.37173 3.88611 8.82728 4.34166L14.6583 10.1727C15.1139 10.6283 15.1139 11.3681 14.6583 11.8236C14.2028 12.2792 13.463 12.2792 13.0074 11.8236L8 6.81622L2.99258 11.82C2.53703 12.2755 1.79721 12.2755 1.34166 11.82C0.886112 11.3644 0.886112 10.6246 1.34166 10.1691L7.17272 4.33802L7.17636 4.34166Z" fill="#53535F"/>
    </svg>
  );
};
const IconFlag = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3334 1.10236L8.49462 16H7.02695L10.3452 7.62205L7.72888 6.70866L7.25029 7.9685L1.66675 6.07874L4.0597 0L8.17556 1.41732L8.68606 0.15748L12.7062 1.54331L13.0572 0.661417L14.3334 1.10236Z" fill="#53535F"/>
    </svg>
  );
};
const IconFlagOutline = () => {
  return (
    <svg width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.5253 2.50708C30.2962 2.42843 29.0344 1.99787 28.5954 1.84228C27.516 1.46024 27.466 1.48869 27.0565 2.56619C26.9768 2.77255 26.6449 3.64592 26.6449 3.64592C26.6449 3.64592 18.3683 0.73521 18.0814 0.636495C17.0148 0.280115 16.9736 0.296847 16.5664 1.37825C16.468 1.64317 15.8354 3.28007 15.8354 3.28007C15.8354 3.28007 7.43856 0.350944 7.04892 0.217652C5.99539 -0.149884 5.95306 -0.140402 5.52882 0.9477C5.35074 1.40671 0.684361 13.6323 0.268337 14.7523C-0.135045 15.8504 -0.10317 15.8845 0.497508 16.0947C0.790428 16.1979 12.8039 20.3918 13.1585 20.5195C14.2598 20.9038 14.278 20.8938 14.5676 20.1297C14.6874 19.8224 15.2073 18.4276 15.4397 17.8431C15.8624 17.9887 19.9345 19.4058 20.5512 19.6301C20.3414 20.2101 12.7859 40 12.7859 40C12.7859 40 13.9141 40 14.0466 40C14.2554 39.2432 21.5856 20.2375 21.6663 20.0216C22.0779 18.9361 22.0428 18.8877 20.9782 18.5073C20.6964 18.4125 16.1102 16.8237 15.8629 16.7344C14.7781 16.3467 14.7445 16.3541 14.3313 17.4461C14.2824 17.5772 13.7278 19.0228 13.5855 19.3986C13.3146 19.3116 1.58291 15.2163 1.38506 15.1393C1.48838 14.8706 6.48012 1.72069 6.62904 1.3303C6.86535 1.40558 15.2342 4.3347 15.4244 4.39718C16.469 4.77307 16.5229 4.75522 16.9499 3.67103C17.0373 3.45296 17.5495 2.10217 17.6727 1.77534C18.4645 2.05031 24.5537 4.19304 26.2179 4.76136C27.2675 5.12275 27.345 5.11717 27.7587 4.03354C27.8594 3.76807 28.1073 3.12557 28.1738 2.95213C28.3969 3.02909 29.8807 3.54833 30.1061 3.63756C30.0071 3.9008 16.541 39.1914 16.2459 39.9996C16.4081 39.9996 17.3296 39.9973 17.5028 39.9996C17.6277 39.69 31.1486 4.21369 31.22 4.01568C31.636 2.93428 31.5861 2.88576 30.5253 2.50708Z" fill="#0090CB"/>
    </svg>
  );
};
const IconLightbulb = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 12C10.8 11.0031 11.4219 10.1531 12.0375 9.30625C12.2 9.08437 12.3625 8.8625 12.5188 8.6375C13.1375 7.74687 13.5 6.66875 13.5 5.50313C13.5 2.4625 11.0375 0 8 0C4.9625 0 2.5 2.4625 2.5 5.5C2.5 6.66563 2.8625 7.74687 3.48125 8.63437C3.6375 8.85938 3.8 9.08125 3.9625 9.30313C4.58125 10.15 5.20312 11.0031 5.5 11.9969H10.5V12ZM8 16C9.38125 16 10.5 14.8813 10.5 13.5V13H5.5V13.5C5.5 14.8813 6.61875 16 8 16ZM5.5 5.5C5.5 5.775 5.275 6 5 6C4.725 6 4.5 5.775 4.5 5.5C4.5 3.56562 6.06562 2 8 2C8.275 2 8.5 2.225 8.5 2.5C8.5 2.775 8.275 3 8 3C6.61875 3 5.5 4.11875 5.5 5.5Z" fill="#53535F"/>
    </svg>
  );
};
const IconLogout = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 13H3C2.44781 13 2 12.5522 2 12V4C2 3.44781 2.44781 3 3 3H5C5.55219 3 6 2.55219 6 2C6 1.44781 5.55312 1 5 1H3C1.34312 1 0 2.34312 0 4V12C0 13.6569 1.34312 15 3 15H5C5.55219 15 6 14.5522 6 14C6 13.4478 5.55312 13 5 13ZM15.7063 7.29375L11.7063 3.29375C11.3153 2.90281 10.6825 2.90344 10.2922 3.29375C9.90156 3.68438 9.90156 4.31719 10.2922 4.70781L12.5875 7H6C5.44688 7 5 7.44688 5 8C5 8.55312 5.44719 9 6 9H12.5875L10.2944 11.2931C9.90375 11.6838 9.90375 12.3166 10.2944 12.7072C10.685 13.0978 11.3178 13.0978 11.7084 12.7072L15.7084 8.70719C16.0969 8.31563 16.0969 7.68437 15.7063 7.29375Z" fill="#53535F"/>
    </svg>
  );
};
const IconMenu = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2.14286C0 1.51071 0.510714 1 1.14286 1H14.8571C15.4893 1 16 1.51071 16 2.14286C16 2.775 15.4893 3.28571 14.8571 3.28571H1.14286C0.510714 3.28571 0 2.775 0 2.14286ZM0 7.85714C0 7.225 0.510714 6.71429 1.14286 6.71429H14.8571C15.4893 6.71429 16 7.225 16 7.85714C16 8.48929 15.4893 9 14.8571 9H1.14286C0.510714 9 0 8.48929 0 7.85714ZM16 13.5714C16 14.2036 15.4893 14.7143 14.8571 14.7143H1.14286C0.510714 14.7143 0 14.2036 0 13.5714C0 12.9393 0.510714 12.4286 1.14286 12.4286H14.8571C15.4893 12.4286 16 12.9393 16 13.5714Z" fill="#53535F"/>
    </svg>
  );
};
const IconPen = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3342 0.603027L9.82166 2.11553L13.8842 6.17803L15.3967 4.66553C16.1779 3.88428 16.1779 2.61865 15.3967 1.8374L14.1654 0.603027C13.3842 -0.178223 12.1185 -0.178223 11.3373 0.603027H11.3342ZM9.11541 2.82178L1.83103 10.1093C1.50603 10.4343 1.26853 10.8374 1.13728 11.278L0.0310334 15.0374C-0.0470916 15.303 0.0247834 15.5874 0.218533 15.7812C0.412283 15.9749 0.696658 16.0468 0.959158 15.9718L4.71853 14.8655C5.15916 14.7343 5.56228 14.4968 5.88728 14.1718L13.1779 6.88428L9.11541 2.82178Z" fill="#53535F"/>
    </svg>
  );
};
const IconPlus = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 8C15 8.59534 14.5177 9.07726 13.9231 9.07726H9.07692V13.9234C9.07692 14.5188 8.59466 15 8 15C7.40534 15 6.92308 14.5188 6.92308 13.9234V9.07726H2.07692C1.48226 9.07726 1 8.59534 1 8C1 7.40466 1.48226 6.92341 2.07692 6.92341H6.92308V2.07726C6.92308 1.48192 7.40534 1 8 1C8.59466 1 9.07692 1.48192 9.07692 2.07726V6.92341H13.9231C14.5188 6.92308 15 7.40433 15 8Z" fill="#53535F"/>
    </svg>
  );
};
const IconSearch = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.6343 13.8656L11.8937 10.125C12.7443 8.86218 13.164 7.28436 12.9393 5.60311C12.5562 2.74343 10.2125 0.416239 7.34996 0.053833C3.09403 -0.484698 -0.484724 3.09405 0.0538389 7.34999C0.416339 10.2137 2.74384 12.5594 5.60384 12.9406C7.28509 13.1653 8.86321 12.7457 10.1257 11.895L13.8663 15.6356C14.3545 16.1237 15.146 16.1237 15.6342 15.6356C16.1218 15.1469 16.1218 14.3531 15.6343 13.8656ZM2.47184 6.49999C2.47184 4.29436 4.26621 2.49999 6.47184 2.49999C8.67746 2.49999 10.4718 4.29436 10.4718 6.49999C10.4718 8.70561 8.67746 10.5 6.47184 10.5C4.26621 10.5 2.47184 8.70624 2.47184 6.49999Z" fill="#53535F"/>
    </svg>
  );
};
const IconTrash = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.225 0.553125L4 1H1C0.446875 1 0 1.44687 0 2C0 2.55312 0.446875 3 1 3H13C13.5531 3 14 2.55312 14 2C14 1.44687 13.5531 1 13 1H10L9.775 0.553125C9.60625 0.2125 9.25938 0 8.88125 0H5.11875C4.74062 0 4.39375 0.2125 4.225 0.553125ZM13 4H1L1.6625 14.5938C1.7125 15.3844 2.36875 16 3.15937 16H10.8406C11.6312 16 12.2875 15.3844 12.3375 14.5938L13 4Z" fill="#53535F"/>
    </svg>
  );
};
const IconWarning = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.99885 0C8.4426 0 8.85197 0.234375 9.07697 0.61875L15.827 12.1187C16.0551 12.5062 16.0551 12.9844 15.8332 13.3719C15.6113 13.7594 15.1957 14 14.7488 14H1.24885C0.801972 14 0.386347 13.7594 0.164472 13.3719C-0.0574034 12.9844 -0.0542784 12.5031 0.170722 12.1187L6.92072 0.61875C7.14572 0.234375 7.5551 0 7.99885 0ZM7.99885 4C7.58322 4 7.24885 4.33437 7.24885 4.75V8.25C7.24885 8.66562 7.58322 9 7.99885 9C8.41447 9 8.74885 8.66562 8.74885 8.25V4.75C8.74885 4.33437 8.41447 4 7.99885 4ZM8.99885 11C8.99885 10.4469 8.55197 10 7.99885 10C7.44572 10 6.99885 10.4469 6.99885 11C6.99885 11.5531 7.44572 12 7.99885 12C8.55197 12 8.99885 11.5531 8.99885 11Z" fill="#53535F"/>
    </svg>
  );
};
const IconX = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.21774 3.26906C1.92742 2.97874 1.92742 2.50805 2.21774 2.21774C2.50805 1.92742 2.97874 1.92742 3.26906 2.21774L13.7823 12.7309C14.0726 13.0213 14.0726 13.4919 13.7823 13.7823C13.4919 14.0726 13.0213 14.0726 12.7309 13.7823L2.21774 3.26906Z" fill="#53535F"/>
      <path d="M12.7309 2.21774C13.0213 1.92742 13.4919 1.92742 13.7823 2.21774C14.0726 2.50805 14.0726 2.97874 13.7823 3.26906L3.26906 13.7823C2.97874 14.0726 2.50805 14.0726 2.21774 13.7823C1.92742 13.4919 1.92742 13.0213 2.21774 12.7309L12.7309 2.21774Z" fill="#53535F"/>
    </svg>
  );
};

export {
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconFlag,
  IconFlagOutline,
  IconLightbulb,
  IconLogout,
  IconMenu,
  IconPen,
  IconPlus,
  IconSearch,
  IconTrash,
  IconWarning,
  IconX,
};
