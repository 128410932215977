import DangerousIcon from '@mui/icons-material/Dangerous';
import CheckIcon from '@mui/icons-material/Check';

export type PropsIcon = {
  hasError: boolean,
}

export function ModalIcon({
  hasError
}: PropsIcon) {
  const getIcon = (hasError: boolean) => {
    if (hasError) {
      return <DangerousIcon className='text-red'/>
    }

    return <CheckIcon className='text-green-900'/>
  }

  return (
    <>
      {getIcon(hasError)}
    </>
  )
}