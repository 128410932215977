import { createSlice } from '@reduxjs/toolkit';

//Estado global para guardar a consulta que irá abrir na modal e será editada
export const searchEditionSlice = createSlice({
  name: 'searchEdition',
  initialState: {
    value: {
      id: '',
      name: '',
      graphqlQueryName: '',
      graphqlCountName: '',
      accessLevel: '',
      query: '',
      variables: '',
      headers: '',
      fields: '',
      filters: '',
      executeOnInit: false,
      showLoginButton: false
    } as any
  },
  reducers: {
    setSearch: (state, action) => {
      state.value = action.payload;
    }
  }
})

export const { setSearch } = searchEditionSlice.actions

export default searchEditionSlice.reducer