import React, { ReactElement, FC } from 'react';
import './Base.scss';

interface BaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle: string, // cor do botão. tipos: filled, filled-secondary, flat, outline, card
  label?: string, // texto do botão
  tooltip?: string, // tooltip do botão
  round?: boolean, // remove o label, deixa o botão redondo só com ícone
  icon?: ReactElement, // recebe um componente de ícone svg
  size?: string, // tamanho (css) do botão. tipos: 'md', 'lg'
  ellipsis?: boolean, // adiciona '...' e tooltip no texto quando ficar muito grande
  disabled?: boolean, // desativa o callback do botão e muda o estilo
  onClick?: React.MouseEventHandler<HTMLButtonElement>, // função
}

const BaseButton: FC<BaseButtonProps> = (props) => {
  const baseButtonLabelRef = React.createRef<HTMLElement>();

  function getButtonClasses() {
    const baseClass = 'base-button';
    const styleClass = props.buttonStyle;
    const roundClass = props.round ? 'button-round' : '';
    const disabledClass = props.disabled ? 'button-disabled' : '';

    let sizeClass = 'button-medium';
    if (props.size === 'lg') sizeClass = 'button-large';

    const strArray = [baseClass, styleClass, roundClass, disabledClass, sizeClass, props.className];
    return strArray.join(' ').trim();
  };

  function getLabelClasses() {
    const baseClass = 'button-label';
    const ellipsisClass = props.ellipsis ? 'button-ellipsis' : '';

    const strArray = [baseClass, ellipsisClass];
    return strArray.join(' ').trim();
  };

  return (
    <button
      {...props}
      className={getButtonClasses()}
      onClick={!props.disabled ? props.onClick : undefined}
    >
      {!props.round && 
        <span
          className={getLabelClasses()}
          ref={baseButtonLabelRef}
        >
          {props.label}
        </span>
      }
      {props.icon &&
        <div className="button-icon">
          {props.icon}
        </div>
      }
      {props.tooltip &&
        <span className="button-tooltip text-small">
          {props.tooltip}
        </span>
      }
    </button>
  )
};

export default BaseButton;