import Select from 'react-select';

const accessLevels = [
  { value: 'admin', label: 'Desenvolvedor'},
  { value: 'gestor', label: 'Gestor'},
  { value: 'comercial', label: 'Comercial'},
  { value: 'suporte', label: 'Suporte'}
]

export type PropsAccessLevel = {
  isMulti: boolean,
  onChange?: any,
  access?: string[],
}

export function AccessLevelField({
  isMulti,
  onChange,
  access,
}: PropsAccessLevel) {
  return (
    <Select 
      className='rounded-full mb-2' 
      placeholder="Nível de acesso"
      options={accessLevels} 
      isMulti={isMulti} 
      defaultValue={accessLevels.filter(a => access?.includes(a.value))}
      onChange={onChange} 
    />
  )
}