import { MongoService } from "./mongo.service";

export class AccountService {
  private readonly _url = "/account";
  private readonly mongoService: MongoService;

  public constructor() {
    this.mongoService = new MongoService();
  }

  public post(data: any, callback: any): void {
    this.mongoService.post(this._url+'/signup', data, callback);
  }
}