import { Field } from "./field";
import { FieldBuilder } from "./field-builder";

export class FieldFactory {
  public addField(field: Field) {
    switch (field.type) {
      case "text":
        return FieldBuilder.addTextField(field);
      case "number":
        return FieldBuilder.addNumberField(field);
      case "select":
        return FieldBuilder.addSelectField(field);
      case "date":
        return FieldBuilder.addDateField(field);
      case "boolean":
        return FieldBuilder.addCheckField(field);
      default:
        return FieldBuilder.addTextField(field);
    }
  }
}