import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { messages } from 'messages';
import { Tab, Tabs } from '@mui/material';

import { useAuth } from 'providers/Auth.provider';
import { FeatureService } from 'services/feature.service';
import { setCurrentFeature } from 'slices/current-feature-to-execution.slice';
import { openEditFeatureModal } from 'slices/edit-feature-modal.slice';
import { setFeature } from 'slices/feature-in-edition.slice';
import { toggleRight } from 'slices/right-sidebar.slice';
import { changeStatusTransactionFeature } from 'slices/transaction-feature.slice';

import BaseButton from 'components/Base/BaseButton';
import { DeleteModal } from 'components/DeleteModal/DeleteModal';
import { ReturnModal } from 'components/ReturnModal/ReturnModal';
import { EditFeatureModal } from 'layout/Modal/EditFeatureModal';
import FeatureFormModal from 'layout/Sidebar/FeatureFormModal';

import './Sidebar.scss';
import { IconChevronLeft, IconChevronRight, IconPen, IconTrash } from 'assets/svg/Icons';

export default function RightSidebar() {
  const context = useAuth();
  const dispatch = useDispatch();
  
  const [individualFeatures, setIndividualFeatures] = useState([] as any[]);
  const [inLargeScaleFeatures, setLargeScaleFeatures] = useState([] as any[]);
  const [activeTab, setActiveTab] = useState('individual');
  const [modalToExecuteIsOpen, setModalToExecuteFeatureIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [featureIdToDelete, setFeatureIdToDelete] = useState("");
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState(false);

  const currentSearch = useSelector((state: RootState) => state.search.value)
  const selectedItems = useSelector((state: RootState) => state.selectedItems.value);
  const editFeatureModalState = useSelector((state: RootState) => state.editFeatureModal.value);
  const company = useSelector((state: RootState) => state.selectedCompany.value);
  const featureEdition = useSelector((state: RootState) => state.featureEdition.value);
  const transactionFeature = useSelector((state: RootState) => state.transactionFeature);
	const rightIsOpen = useSelector((state: RootState) => state.rightSidebar.value);

  const refreshFeatures = () => {
    dispatch(changeStatusTransactionFeature(true));
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const handleClickFeature = (feature: any) => {
    if (selectedItems.length === 0) {
      alert("Selecione 1 item");

      return;
    } else {
      if (feature.type === "individual") {
        if (selectedItems.length > 1) {
          alert("Selecione apenas 1 item pra funcionalidades individuais");

          return;
        }
      }

      dispatch(setCurrentFeature(feature));
      setModalToExecuteFeatureIsOpen(true);
    }
  }

  const handleClickEdit = (feature: any) => {
    const data = {
      id: feature._id,
      name: feature.name,
      query: feature.query,
      queryVariables: JSON.stringify(feature.queryVariables),
      queryHeaders: JSON.stringify(feature.queryHeaders),
      fields: JSON.stringify(feature.fields),
      searchsIn: feature.searchs,
      type: feature.type,
      executeWithoutFields: feature.executeWithoutFields
    }

    dispatch(setFeature(data));
    dispatch(openEditFeatureModal());
  }

  const handleClickDelete = (id: string) => {
    setDeleteModalIsOpen(true);
    setFeatureIdToDelete(id);
    setDeleteModalIsOpen(true);
  }

  const deleteFeature = (id: string) => {
    const service = new FeatureService();

    service.delete(id, refreshFeatures);

    setMessage(messages["SUCCESS"]);
    setHasError(false);
    setShowReturnModal(true);
  }

  const handleClickLogin = async () => {
    if (selectedItems.length === 0) {
      alert("Selecione 1 item");

      return;
    } else {
      if (selectedItems.length > 1) {
        alert("Selecione apenas 1 item para logar");

        return;
      }
      
      window.open(
        `https://beta.minhavisita.com.br/admin/mv20&id_usuario=${company.mainUserId}&id_empresa=${company.companyId}`, 
        "_blank"
      );
    }
  }

  useEffect(() => {
    async function getFeatures(currentSearch: any) {
      if (context.setHeaders) context.setHeaders();

      if (currentSearch) {
        const service = new FeatureService();
        service.getBySearchIds({ searchIds: currentSearch._id }, splitFeaturesByType);
      }
    }

    async function splitFeaturesByType(res: any) {
      const individualFeatures = res.filter((feature: any) => feature.type === "individual")
      setIndividualFeatures(individualFeatures);

      const largeScaleFeatures = res.filter((feature: any) => feature.type === "massa")
      setLargeScaleFeatures(largeScaleFeatures);
    }

    getFeatures(currentSearch);
  }, [context, currentSearch, selectedItems, transactionFeature]);
  
  function getSidebarClasses() {
    const baseClass = 'sidebar right-sidebar';
    const openClass = rightIsOpen ? 'sidebar-open' : 'sidebar-closed';

    return [baseClass, openClass].join(' ').trim();
  };

  return (
    <div id="right" className={getSidebarClasses()}>
      <BaseButton
        id="right-button"
        buttonStyle="filled-secondary"
        round
        className="sidebar-button right-sidebar-button"
        icon={rightIsOpen ? <IconChevronRight /> : <IconChevronLeft />}
        onClick={() => dispatch(toggleRight())}
      />

      <div className="sidebar-content">
        <span className="text-large text-secondary-text">Funcionalidades</span>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          variant="fullWidth"
          textColor='inherit'
          className="sidebar-tabs"
        >
          <Tab value="individual" label="Individuais" />
          <Tab value="mass" label="Em massa" />
        </Tabs>

        {currentSearch &&
          activeTab === 'individual' &&
          <div className="sidebar-items">
            {currentSearch.showLoginButton &&
              <div className="sidebar-item">
                <BaseButton
                  buttonStyle="filled"
                  label="Logar no MV20"
                  onClick={handleClickLogin}
                  ellipsis
                  disabled={selectedItems.length !== 1}
                />
              </div>
            }
            {individualFeatures &&
              individualFeatures.map((feature: any) => {
                return (
                  <div key={feature._id} className="sidebar-item">
                    <BaseButton
                      buttonStyle="flat"
                      label={feature.name}
                      ellipsis
                      onClick={() => handleClickFeature(feature)}
                    />
      
                    {context.accessLevel === "admin" &&
                      <div className="sidebar-item-buttons">
                        <BaseButton
                          buttonStyle="card"
                          round
                          tooltip="Editar"
                          icon={<IconPen />}
                          onClick={() => handleClickEdit(feature)}
                        />
                        
                        <BaseButton
                          buttonStyle="card"
                          round
                          tooltip="Excluir"
                          icon={<IconTrash />}
                          onClick={() => handleClickDelete(feature.id)}
                        />
                      </div>
                    }
                  </div>
                )
              })
            }
          </div>
        }

        {currentSearch &&
          activeTab === 'mass' &&
          <div className="sidebar-items">
            {inLargeScaleFeatures &&
              inLargeScaleFeatures.map((feature: any) => {
                return (
                  <div key={feature._id} className="sidebar-item">
                    <BaseButton
                      buttonStyle="flat"
                      label={feature.name}
                      ellipsis
                      onClick={() => handleClickFeature(feature)}
                    />

                    {context.accessLevel === "admin" &&
                      <div className="sidebar-item-buttons">
                        <BaseButton
                          buttonStyle="card"
                          round
                          tooltip="Editar"
                          icon={<IconPen />}
                          onClick={() => handleClickEdit(feature)}
                        />
                        
                        <BaseButton
                          buttonStyle="card"
                          round
                          tooltip="Excluir"
                          icon={<IconTrash />}
                          onClick={() => handleClickDelete(feature._id)}
                        />
                      </div>
                    }
                  </div>
                )
              })
            }
          </div>
        }
      </div>
      {modalToExecuteIsOpen &&
        <FeatureFormModal
          modalIsOpen={modalToExecuteIsOpen}
          setModalIsOpen={setModalToExecuteFeatureIsOpen}
        />
      }
      {featureEdition.id !== '' &&
        <EditFeatureModal featureModalOpen={editFeatureModalState} />
      }
      {deleteModalIsOpen &&
        <DeleteModal 
          show={deleteModalIsOpen}
          setShow={setDeleteModalIsOpen}
          handleClickDelete={() => deleteFeature(featureIdToDelete)}
        />
      }
      {showReturnModal &&
        <ReturnModal
          message={message}
          hasError={hasError}
          show={showReturnModal}
          closeModal={setShowReturnModal}
        />
      }
    </div>
  )
}