import axios, { AxiosInstance } from "axios";

export class MongoService {
  public client: AxiosInstance;

  public constructor() {
    console.log('URL DA API: ' + process.env.REACT_APP_EXTRANET_API_URL);
    
    this.client = axios.create({
      baseURL: process.env.REACT_APP_EXTRANET_API_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  public get(url: string, callback: any) {
    this.client.get(url)
    .then((response) => callback(response.data))
    .catch((error) => this.verifyAndRemoveAccessToken(error, callback));
  }

  public post(url: string, data: any, callback: any): void {
    this.client.post(url, data)
    .then((response) => callback(response.data))
    .catch((error) => this.verifyAndRemoveAccessToken(error, callback));
  }

  public put(url: string, data: any, callback: any) {
    this.client.put(url, {
      ...data
    })
    .then((response) => callback(response.data))
    .catch((error) => this.verifyAndRemoveAccessToken(error, callback));
  }

  public delete(url: string, callback: any) {
    this.client.delete(url)
    .then((response) => callback(response.data))
    .catch((error) => this.verifyAndRemoveAccessToken(error, callback))
  }

  private verifyAndRemoveAccessToken(error: any, callback: any): any {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("accessLevel");
      localStorage.removeItem("userId");

      window.location.href = "/login";
    }

    callback(error);
  }
}