import { configureStore } from '@reduxjs/toolkit'
import modalReducer from './slices/modal.slice'
import searchModalReducer from './slices/search-modal.slice'
import editSearchModalReducer from './slices/edit-search-modal.slice'
import featureModalReducer from './slices/feature-modal.slice'
import editFeatureModalReducer from './slices/edit-feature-modal.slice'
import searchReducer from './slices/search-to-execution.slice'
import leftSidebarReducer from './slices/left-sidebar.slice'
import rightSidebarReducer from './slices/right-sidebar.slice'
import layoutModeReducer from './slices/layout-mode.slice'
import selectedItemsReducer from './slices/selected-items.slice'
import currentFeatureReducer from './slices/current-feature-to-execution.slice'
import featureEditionReducer from  './slices/feature-in-edition.slice'
import searchEditionReducer from './slices/search-in-edition.slice'
import selectedCompanyReducer from './slices/selected-company.slice'
import transactionSearchReducer from './slices/transaction-search.slice'
import transactionFeatureReducer from './slices/transaction-feature.slice'
import darkmodeReducer from './slices/darkmode.slice'

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    searchModal: searchModalReducer,
    editSearchModal: editSearchModalReducer,
    featureModal: featureModalReducer,
    editFeatureModal: editFeatureModalReducer,
    search: searchReducer,
    leftSidebar: leftSidebarReducer,
    rightSidebar: rightSidebarReducer,
    layoutMode: layoutModeReducer,
    selectedItems: selectedItemsReducer,
    currentFeature: currentFeatureReducer,
    featureEdition: featureEditionReducer,
    searchEdition: searchEditionReducer,
    selectedCompany: selectedCompanyReducer,
    transactionFeature: transactionFeatureReducer,
    transactionSearch: transactionSearchReducer,
    darkmode: darkmodeReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch