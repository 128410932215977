import { MongoService } from "./mongo.service";

export class AuthService {
  private readonly _url = "/auth";
  private readonly mongoService: MongoService;

  public constructor() {
    this.mongoService = new MongoService();
  }

  public login(data: any, callback: any): void {
    this.mongoService.post(`${this._url}/login`, data, callback);
  }

  public verifyToken(data: any, callback: any) {
    this.mongoService.post(`${this._url}/login/verify/token`, data, callback);
  }
}