import { useDispatch, useSelector } from "react-redux";
import { FeatureModal } from "../../components/FeatureModal/FeatureModal";
import { RootState } from "../../store";
import { FeatureService } from "../../services/feature.service";
import { useState } from "react";
import { ReturnModal } from "../../components/ReturnModal/ReturnModal";
import { closeEditFeatureModal } from "../../slices/edit-feature-modal.slice";
import { changeStatusTransactionFeature } from "../../slices/transaction-feature.slice";

export type PropsEditFeatureModal = {
  featureModalOpen: boolean,
}

export function EditFeatureModal({
  featureModalOpen
}: PropsEditFeatureModal) {
  const dispatch = useDispatch();
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const featureEdition = useSelector((state: RootState) => state.featureEdition.value);

  const showModal = (response: any) => {
    if (response) {
      dispatch(closeEditFeatureModal());
      setMessage("Operação realizada com sucesso!");
      setHasError(false);
      setShowReturnModal(true);
    }

    dispatch(changeStatusTransactionFeature(true));
  };

  const onClick = (data: any) => {
    const service = new FeatureService();

    service.put(featureEdition.id, data, showModal);
  }

  return (
    <>
      <FeatureModal
        featureModalOpen={featureModalOpen}
        onClick={onClick}
        featureName={featureEdition.name}
        featureType={featureEdition.type}
        featureSearchsIn={featureEdition.searchsIn}
        featureQuery={featureEdition.query}
        featureQueryHeaders={featureEdition.queryHeaders}
        featureQueryVariables={featureEdition.queryVariables}
        featureFields={featureEdition.fields}
        featureExecuteWithoutFields={featureEdition.executeWithoutFields}
      />
      {showReturnModal &&
        <ReturnModal
          message={message}
          hasError={hasError}
          show={showReturnModal}
          closeModal={setShowReturnModal}
        />
      }
    </>
  )
}