
import { createSlice } from '@reduxjs/toolkit';

// Estado global para guardar se o modo escuro está ativado ou não, e salvar/carregar no localStorage
export const darkmodeSlice = createSlice({
  name: 'darkmode',
  initialState: {
    active: false,
  },
  reducers: {
    toggleDarkmode: (state) => {
      state.active = !state.active;
      document.documentElement.setAttribute('darkmode', state.active ? 'true' : 'false');
      localStorage.setItem('darkmode', state.active ? 'true' : 'false');
    },
    enableDarkmode: (state) => { 
      state.active = true;
      document.documentElement.setAttribute('darkmode', 'true');
      localStorage.setItem('darkmode', 'true');
    },
    disableDarkmode: (state) => { 
      state.active = false;
      document.documentElement.setAttribute('darkmode', 'false');
      localStorage.setItem('darkmode', 'false');
    },
    bootDarkmode: (state) => {
      state.active = localStorage.getItem("darkmode") === 'true';
      document.documentElement.setAttribute('darkmode', state.active ? 'true' : 'false');
    }
  }
})

export const {
  toggleDarkmode,
  enableDarkmode,
  disableDarkmode,
  bootDarkmode
} = darkmodeSlice.actions

export default darkmodeSlice.reducer