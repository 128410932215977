import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import Login from "../pages/Login";
import CreateUsers from "../pages/CreateUsers";
import Logs  from "../pages/Logs";

const Routes = () => {
  const routesForNotAuthenticated = [
    {
      path: "/login",
      element: <Login />
    }
  ];

  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />
    },
    {
      path: "/users",
      element: <CreateUsers />,
    },
    {
      path: "/logs",
      element: <Logs />
    }
  ];

  const router = createBrowserRouter([
    ...routesForNotAuthenticated,
    ...routesForAuthenticatedOnly,
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;