import { Field } from "./field";
import { gql, useQuery } from "@apollo/client";
import { SelectOption } from "../types";
import Select from 'react-select';

export type PropsSelectField = {
  field: Field
}

export function SelectField({
  field
}: PropsSelectField) {
  const query = gql`${field.query}`;
  
  const { data } = useQuery(query, {
    context: {
      headers: field.queryHeadersOfField
    }
  });

  let options: SelectOption[] = [];

  if(data) {
    if(field.graphqlQueryNameOfField) {
      data[field.graphqlQueryNameOfField].forEach((item: any) => {
        options.push({ "value": item.id, "label": item.name })
      });
    }
  }

  return (
    <Select 
      options={options} 
      className={`${field.classes} mr-1 mb-1 rounded-full`}
      id={field.variableName}
    />
  );
}