import axios from "axios";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";

interface IAuthContext {
  profile: any;
  setProfile?: (response: any) => void;
  accessToken?: string;
  accessLevel?: string;
  setHeaders?: () => void;
}

const AuthContext = createContext<IAuthContext>({ profile: null });

const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const [profile, setProfile_] = useState({} as any);
  const [accessLevel, setAccessLevel] = useState(localStorage.getItem("accessLevel"));
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"));
  const [userId, setUserId] = useState(localStorage.getItem("userId"));

  const setProfile = useCallback((newProfile: any) => {
    setProfile_(newProfile);
    setAccessLevel(newProfile.accessLevel);
    setAccessToken(newProfile.accessToken);
    setUserId(newProfile.userId);

    localStorage.setItem("accessToken", newProfile.accessToken);
    localStorage.setItem("accessLevel", newProfile.accessLevel);
    localStorage.setItem("userId", newProfile.userId);
  }, [setProfile_, setAccessLevel, setAccessToken, setUserId]);

  const setHeaders = useCallback(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    axios.defaults.headers.common["UserId"] = userId;
  }, [accessToken, userId]);

  const contextValue: any = useMemo(
    () => ({
      profile,
      setProfile,
      accessToken,
      accessLevel,
      userId,
      setHeaders
    }),
    [profile, setProfile, accessToken, accessLevel, userId, setHeaders]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;