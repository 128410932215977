import { useEffect, useState } from "react";
import { useAuth } from "../providers/Auth.provider";
import { LogService } from "../services/log.service";
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';

export default function Logs() {
  const context = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [logs, setLogs] = useState([]);
  const [count, setCount] = useState(0);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  async function getAndSetLogs(response: any) {
    const allLogs = response['logs'].map((log: any) => {
      return {
        "id": log._id,
        "message": log.message,
        "createdAt": log.createdAt,
      }
    });

    setCount(response['count']);
    setLogs(allLogs);
  }

  const handleClick = () => {
    navigate("/");
  }

  const handleClickSearch = (event: any) => {
    event.preventDefault();

    if (context.setHeaders) context.setHeaders();

    const service = new LogService();

    if (startDate && endDate) {
      service.postWithParams(page, pageSize, startDate, endDate, getAndSetLogs)
    }
    else {
      service.get(page, pageSize, getAndSetLogs);
    }
  }

  useEffect(() => {
    async function getLogs() {
      if (context.setHeaders) context.setHeaders();

      const service = new LogService();
      service.get(page, pageSize, getAndSetLogs);
    }

    getLogs();
  }, [context, page, pageSize]);

  return (
    <div className="p-4">
      <div className="mx-auto text-left w-75" style={{ marginTop: "20px", color: "#0891b2" }}>
        <button type="button" onClick={handleClick}>
          <KeyboardBackspaceIcon />
        </button>
      </div>
      <div className="bg-white p-5 mx-auto rounded-md w-75" style={{ marginTop: "20px" }}>
        <form className="flex flex-wrap items-center justify-init">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
              <DatePicker 
                label="Data inicial" 
                name="startDate" 
                value={startDate}
                onChange={(value) => setStartDate(value)}
              />
            </div>
            <div>
              <label className="ml-2">
                <DatePicker 
                  label="Data final" 
                  name="endDate" 
                  value={endDate}
                  onChange={(value) => setEndDate(value)}
                />
              </label>
            </div>
          </LocalizationProvider>
          <button
            type='submit'
            className="ml-2 bg-blue form-button tooltip"
            onClick={handleClickSearch}
          >
            <span className="tooltip-text">Pesquisar</span>
            <SearchIcon />
          </button>
          <button
            type='submit'
            className="form-button bg-blue ml-1 tooltip"
          >
            <span className="tooltip-text">Limpar busca</span>
            <AutoFixHighIcon />
          </button>
        </form>
      </div>
      {logs &&
        <div id="table-result" className="w-75 bg-white p-5 mx-auto rounded-md table-container" style={{ marginTop: "20px" }}>
          <DataGrid 
            rows={logs}
            columns={[
              {"field": "id", "headerName": "Código", "minWidth": 150}, 
              {"field": "createdAt", "headerName": "Data de criação", "minWidth": 200},
              {"field": "message", "headerName": "Mensagem", "minWidth": 400}, 
            ]}
            paginationMode='server'
            rowCount={count}
            onPaginationModelChange={(model: GridPaginationModel) => {
              setPage(model.page === 0 ? 1 : model.page)
              setPageSize(model.pageSize);
            }}
          />
        </div>
      }
    </div>
  )
}