import { Field } from "./field";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export type PropsDateField = {
  field: Field
}

export function DateField({field}: PropsDateField) {
  return (
    // <DatePicker 
    //   className='rounded-full'
    //   selected={startDate} 
    //   onChange={(date: Date) => setStartDate(date)} 
    //   placeholderText={field.name}
    // />
    <DatePicker 
      label={field.name}
    />
  )
}