import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

//Estado global para guardar se a modal para cadastrar consultas ou funcionalidades está aberta
export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    value: false
  },
  reducers: {
    open: (state) => { state.value = true },
    close: (state) => { state.value = false }
  }
});

export const { open, close } = modalSlice.actions;

export const selectModalOpenState = (state: RootState) => state.modal;

export default modalSlice.reducer;