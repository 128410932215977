import { useState } from "react";
import { FeatureModal } from "../../components/FeatureModal/FeatureModal";
import { FeatureService } from "../../services/feature.service";
import { ReturnModal } from "../../components/ReturnModal/ReturnModal";
import { useDispatch } from "react-redux";
import { closeFeatureModal } from "../../slices/feature-modal.slice";
import { changeStatusTransactionFeature } from "../../slices/transaction-feature.slice";
import { messages } from "../../messages";

export type PropsCreateFeatureModal = {
  featureModalOpen: boolean,
}

export function CreateFeatureModal({
  featureModalOpen
}: PropsCreateFeatureModal) {
  const dispatch = useDispatch();
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState(false);

  const showModal = (response: any) => {
    if (response) {
      dispatch(closeFeatureModal());
      setMessage(messages["SUCCESS"]);
      setHasError(false);
      setShowReturnModal(true);
    }

    dispatch(changeStatusTransactionFeature(true));
  };

  const onClick = (data: any) => {
    const service = new FeatureService();

    service.post(data, showModal);
  }

  return (
    <>
      <FeatureModal
        featureModalOpen={featureModalOpen}
        onClick={onClick}
      />
      {showReturnModal &&
        <ReturnModal
          message={message}
          hasError={hasError}
          show={showReturnModal}
          closeModal={setShowReturnModal}
        />
      }
    </>
  )
}